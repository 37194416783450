import React from "react";
import { withAllContexts } from "../../../AllContextsWrapper";
import PropTypes from 'prop-types';
import { TextField, Autocomplete, MenuItem, Button, Grid, Box, Step, StepLabel, Stepper, Dialog, Slide, AppBar, Toolbar, IconButton, Typography, Backdrop, CircularProgress } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';

class ReservationForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      activeStep: 0,
      places: [],
      clients: [],
      searchClientStr: "",
      clientEdited: false,
      clientLoading: false,
      clientSelected: false,
      isExistingClient: false,
      client: {
        name: "",
        phone: "",
        email: ""
      }
    };

    if (this.props.currentReservation !== null) {

      let { color, client, fees, payments, ...form } = this.props.currentReservation;
      this.state.form = {
        ...form
      }

    } else {
      this.state.form = {
        client: 0,
        status: "ordered",
        start_date: this.props.initDate ? this.formatDate(this.props.initDate) : "",
        end_date: "",
        start_place: "",
        end_place: "",
        kanoe_count_info_min: 0,
        kanoe_count_info_max: 0,
        katamaran_count_info: 0,
        kanoe_count: 0,
        katamaran_count: 0,
        vest_count: 0,
        paddle_count: 0,
        barrel_count: 0,
        note: ""
      }
    }
  }

  formatDate = (date) => {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = '' + d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day

    let dateStr = [year, month, day].join('-');
    return dateStr;
  }

  getPlaces = async () => {

    this.props.socketIO.socket.emit('places:getAll', (response) => {

      if (response.success) {
        this.setState({
          places: response.data
        })
      } else {
        this.setState({
          places: []
        })
      }
    });
  }

  searchClients = (str) => {
    this.setState({
      searchClientStr: str
    });

    if (str) {

      this.props.socketIO.socket.emit('client:search', str, (response) => {
        if (response.success) {
          this.setState({
            clients: response.data
          })
        } else {
          this.setState({
            clients: []
          })
        }
      });

    } else {
      this.setState({
        clients: []
      })
    }
  }

  handleClientChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(prevState => ({
      clientEdited: true,
      client: {
        ...prevState.client,
        [name]: value
      }
    }))
  }

  updateFormState = (value, name) => {
    console.log('updateFormState', value, name);
    if (name === 'start_date') {

      /* if (!this.state.form.end_date || this.state.form.end_date.isBefore(value)) {
        console.log('isBefore', value, name);
      } */

      if (!this.state.form.end_date || new Date(this.state.form.end_date).getTime() < new Date(value).getTime()) {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            end_date: value
          }
        }))
      }
    }
    if (name === 'kanoe_count_info_min' && (this.state.form.kanoe_count_info_max === 0 || this.state.form.kanoe_count_info_max === "")) {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          kanoe_count_info_max: value
        }
      }))
    }
    if (name === 'kanoe_count_info_min' && (+this.state.form.kanoe_count_info_max < +value)) {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          kanoe_count_info_max: value
        }
      }))
    }
    if (name === 'kanoe_count_info_max' && (this.state.form.kanoe_count_info_min === 0 || this.state.form.kanoe_count_info_min === "")) {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          kanoe_count_info_min: value
        }
      }))
    }
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }))
  }

  handleClientSubmit = () => {
    let client = this.state.client;
    if (client.email.length === 0 && client.phone.length === 0 && client.name.length === 0) {
      enqueueSnackbar('Treba vyplniť aspoň jedno z polí', { variant: 'error' });
      return;
    }


    let strings = {
      endpoint: 'client:create',
      success: 'Klient úspešne vytvorený',
      error: 'Klienta sa nepodarilo vytvoriť'
    }

    if (this.state.isExistingClient) {
      strings = {
        endpoint: 'client:update',
        success: 'Klient úspešne upravený',
        error: 'Klienta sa nepodarilo upraviť'
      }
    }

    client = {
      id: client.id ? client.id : "",
      name: client.name.length > 0 ? client.name : null,
      phone: client.phone.length > 0 ? client.phone : null,
      email: client.email.length > 0 ? client.email : null
    }
    this.setState({
      clientLoading: true
    })

    this.props.socketIO.socket.emit(strings.endpoint, client, (response) => {
      if (response.success) {
        enqueueSnackbar(strings.success, { variant: 'success' });

        let s = {
          client: response.data,
          activeStep: this.state.activeStep + 1,
        }

        if (!this.state.isExistingClient) s.form = { ...this.state.form, client: response.data.id }

        this.setState(s);
      } else {
        enqueueSnackbar(strings.error, { variant: 'error' });
      }

      this.setState({
        clientLoading: false
      })
    });
  }

  updateClientState = (value, name) => {
    this.setState(prevState => ({
      client: {
        ...prevState.client,
        [name]: value
      }
    }))
  }

  handleFormSubmit = () => {

    let strings = {
      endpoint: 'reservations:create',
      success: 'Rezervácia úspešne pridaná',
      error: 'Rezerváciu sa nepodarilo pridať'
    }

    let form = { ...this.state.form };

    /*     if (form.start_date) {
          let startDate = new Date(form.start_date);
          form.start_date = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
        }
    
        if (form.end_date) {
          let endDate = new Date(form.end_date);
          form.end_date = endDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
        } */

    if (!(this.props.currentReservation === null) && this.props.currentReservation.id) {
      strings = {
        endpoint: 'reservations:update',
        success: 'Rezervácia úspešne upravená',
        error: 'Rezerváciu sa nepodarilo upraviť'
      }

      form.client = this.state.client.id;
    }


    this.props.socketIO.socket.emit(strings.endpoint, form, (response) => {
      if (response.success === true) {
        this.props.app.functions.updateMainCalendar();
        this.toggleAdding();
        enqueueSnackbar(strings.success, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(strings.error, {
          variant: "error",
        });
      }
    });
  }

  toggleAdding = () => {
    window.history.back();
    this.setState({
      activeStep: 0,
      places: [],
      clients: [],
      searchClientStr: "",
      clientEdited: false,
      clientLoading: false,
      clientSelected: false,
      isExistingClient: false,
      client: {
        name: "",
        phone: "",
        email: ""
      },
      form: {
        client: 0,
        status: "ordered",
        start_date: "",
        end_date: "",
        start_place: "",
        end_place: "",
        kanoe_count_info_min: 0,
        kanoe_count_info_max: 0,
        katamaran_count_info: 0,
        kanoe_count: 0,
        katamaran_count: 0,
        vest_count: 0,
        paddle_count: 0,
        barrel_count: 0,
        note: ""
      }
    })
  };

  componentDidMount() {
    this.getPlaces();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentReservation, initDate } = this.props;



    if (prevProps.currentReservation !== currentReservation && typeof currentReservation == "object" && currentReservation !== null) {
      this.setState({
        activeStep: 2,
        form: {
          ...this.props.currentReservation,
          client: this.props.currentReservation.client !== undefined ? this.props.currentReservation.client.id : 0,
          start_date: this.props.currentReservation.start_date !== null ? moment(new Date(this.props.currentReservation.start_date)) : moment(new Date()),
          end_date: this.props.currentReservation.end_date !== null ? moment(new Date(this.props.currentReservation.end_date)) : moment(new Date()),
        }
      })
    }

    if (prevProps.currentReservation !== null && currentReservation === null) {
      this.setState({
        activeStep: 0,
        form: {
          client: 0,
          status: "ordered",
          start_date: this.props.initDate !== null ? moment(new Date(this.props.initDate)) : moment(new Date()),
          end_date: this.props.initDate !== null ? moment(new Date(this.props.initDate)) : moment(new Date()),
          start_place: "",
          end_place: "",
          kanoe_count_info_min: 0,
          kanoe_count_info_max: 0,
          katamaran_count_info: 0,
          kanoe_count: 0,
          katamaran_count: 0,
          vest_count: 0,
          paddle_count: 0,
          barrel_count: 0,
          note: ""
        }
      })
    }

    if (prevProps.initDate !== initDate) {
      let f = {
        client: 0,
        status: "ordered",
        start_place: "",
        end_place: "",
        kanoe_count_info_min: 0,
        kanoe_count_info_max: 0,
        katamaran_count_info: 0,
        kanoe_count: 0,
        katamaran_count: 0,
        vest_count: 0,
        paddle_count: 0,
        barrel_count: 0,
        note: "",
        start_date: this.props.initDate !== null ? moment(new Date(this.props.initDate)) : moment(new Date()),
        end_date: this.props.initDate !== null ? moment(new Date(this.props.initDate)) : moment(new Date()),
      }

      this.setState({
        activeStep: 0,
        clients: [],
        searchClientStr: "",
        clientEdited: false,
        clientLoading: false,
        clientSelected: false,
        isExistingClient: false,
        client: {
          name: "",
          phone: "",
          email: ""
        },
        form: {
          ...f
        }
      })
    }
  }

  handleBack = () => {
    if (this.state.activeStep === 0) window.history.back();
    if (this.state.activeStep === 2 && this.props.currentReservation !== null) window.history.back();

    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  handleNext = () => {
    if (this.state.activeStep === 1 && (this.state.clientEdited || !this.state.isExistingClient)) {
      this.handleClientSubmit();
    } else if (this.state.activeStep === 2) {
      this.handleFormSubmit();
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  render() {
    let title = this.props.currentReservation === null ? "Nová rezervácia" : "Úprava rezervácie #" + this.props.currentReservation.id;

    let clientsArray = [];
    if (this.state.clients.length > 0) {
      clientsArray = this.state.clients.map((client) => {
        let label = "";
        if (client.name !== null) label += client.name;
        if (client.phone !== null) label += ", " + client.phone;
        if (client.email !== null) label += ", " + client.email;


        return {
          label: label,
          id: client.id,
          client: client
        }
      });
    }

    let placesArray = [];
    if (this.state.places.length > 0) {
      placesArray = this.state.places.map((place) => {
        return {
          label: place.name,
          id: place.id,
          price: place.price
        }
      });
    }

    const steps = ['Vyhľadanie klienta', 'Údaje klienta', 'Vytvorenie rezerácie'];

    let nextButtonText = 'Ďalej';
    if (this.state.activeStep === 1 && this.state.isExistingClient && this.state.clientEdited) nextButtonText = 'Aktualizovať klienta';
    if (this.state.activeStep === 1 && !this.state.isExistingClient) nextButtonText = 'Vytvoriť klienta';
    if (this.state.activeStep === steps.length - 1) nextButtonText = 'Vytvoriť rezerváciu';
    if (this.state.activeStep === 2 && this.props.currentReservation !== null) nextButtonText = 'Aktualizovať rezerváciu';


    return (

      <Dialog
        fullScreen
        open={this.props.isOpen}
        //onClose={() => this.toggleAdding()}
        TransitionComponent={Transition}
      >

        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => this.toggleAdding()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: '100%', height: '100%', mt: 7, pb: 6, padding: '20px' }}>
          {!this.props.app.state.isCurrentlyEditing &&
            <Stepper activeStep={this.state.activeStep} alternativeLabel sx={{ paddingTop: '20px' }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          }

          {this.state.activeStep === steps.length ? (
            <React.Fragment>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ paddingTop: '15px', paddingBottom: '15px', height: this.props.app.state.isCurrentlyEditing ? '100%' : '100%' }}>
                <Grid container spacing={2}>
                  {this.state.activeStep === 0 && (
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        options={clientsArray}
                        renderInput={(params) => <TextField {...params} label="Hľadať klienta" />}
                        onInputChange={(event, value, reason) => {
                          if (reason === 'input') {
                            this.searchClients(value)
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = options;
                          filtered.unshift({
                            label: "Nový klient",
                            id: "new-client"
                          })

                          return filtered;
                        }}
                        onChange={(event, option, reason) => {
                          if (reason === 'selectOption') {
                            if (option.id === "new-client") {
                              this.setState({
                                clientSelected: true,
                                activeStep: 1,
                                isExistingClient: false,
                                client: {
                                  name: "",
                                  phone: "",
                                  email: ""
                                }
                              })
                            } else {
                              this.setState({
                                clientSelected: true,
                                activeStep: 1,
                                isExistingClient: true,
                                client: {
                                  ...option.client
                                },
                                form: {
                                  ...this.state.form,
                                  client: option.id
                                }
                              })
                            }
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {this.state.activeStep === 1 && (
                    <>
                      <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.state.clientLoading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          name="name"
                          label="Meno"
                          fullWidth
                          autoComplete="none"
                          value={this.state.client.name}
                          onChange={(e) => this.handleClientChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="phone"
                          name="phone"
                          label="Telefón"
                          fullWidth
                          autoComplete="none"
                          value={this.state.client.phone}
                          onChange={(e) => this.handleClientChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          fullWidth
                          autoComplete="none"
                          value={this.state.client.email}
                          onChange={(e) => this.handleClientChange(e)}
                        />
                      </Grid>
                    </>
                  )}
                  {this.state.activeStep === 2 && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          label="Stav"
                          name="status"
                          select
                          value={this.state.form.status}
                          autoComplete="none"
                          fullWidth
                          onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
                        >
                          {
                            this.props.app.functions.getReservationStatusStrings().map((status, index) => {
                              return (
                                <MenuItem value={status.value} key={status.value}>{status.label}</MenuItem >
                              )
                            })
                          }
                        </TextField>
                      </Grid>

                      <Grid item xs={6}>
                        <DatePicker
                          label="Začiatok"
                          name="start_date"
                          defaultValue={moment(new Date())}
                          fullWidth
                          value={this.state.form.start_date}
                          onChange={(value, string) => this.updateFormState(value, 'start_date')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          label="Koniec"
                          name="end_date"
                          defaultValue={moment(new Date())}
                          fullWidth
                          value={this.state.form.end_date}
                          minDate={this.state.form.start_date}
                          onChange={(value, string) => this.updateFormState(value, 'end_date')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          disablePortal
                          options={placesArray}
                          renderInput={(params) => <TextField {...params} label="Začiatok" />}
                          onInputChange={(event, value, reason) => {
                            if (reason === 'input') {
                              this.updateFormState(value, 'start_place');
                            }
                          }}
                          onChange={(event, option, reason) => {
                            if (reason === 'selectOption') {
                              this.updateFormState(option.label, 'start_place');
                            }
                          }
                          }
                          freeSolo
                          value={this.state.form.start_place}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          disablePortal
                          options={placesArray}
                          renderInput={(params) => <TextField {...params} label="Koniec" />}
                          onInputChange={(event, value, reason) => {
                            if (reason === 'input') {
                              this.updateFormState(value, 'end_place');
                            }
                          }}
                          onChange={(event, option, reason) => {
                            if (reason === 'selectOption') {
                              this.updateFormState(option.label, 'end_place');
                            }
                          }
                          }
                          freeSolo
                          value={this.state.form.end_place}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Počet kanoe min"
                          name="kanoe_count_info_min"
                          type="number"
                          autoComplete="none"
                          fullWidth
                          value={this.state.form.kanoe_count_info_min}
                          onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Počet kanoe max"
                          name="kanoe_count_info_max"
                          type="number"
                          autoComplete="none"
                          fullWidth
                          value={this.state.form.kanoe_count_info_max}
                          onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Počet katamaránov"
                          name="katamaran_count_info"
                          type="number"
                          autoComplete="none"
                          fullWidth
                          value={this.state.form.katamaran_count_info}
                          onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Poznámka"
                          name="note"
                          autoComplete="none"
                          multiline
                          fullWidth
                          rows={4}
                          value={this.state.form.note}
                          onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </React.Fragment>
          )}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
          <Button
            color="inherit"
            disabled={this.state.activeStep === 2 && this.props.currentReservation == null}
            variant="outlined"
            onClick={this.handleBack}
            sx={{ mr: 1 }}
          >
            Späť
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={this.handleNext} variant="contained">
            {nextButtonText}
          </Button>

        </Box>

      </Dialog>

    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

ReservationForm.propTypes = {
  currentReservation: PropTypes.object,
  initDate: PropTypes.instanceOf(Date),
};

ReservationForm.defaultProps = {
  currentReservation: null,
  initDate: null,
};


export default withAllContexts(ReservationForm);
