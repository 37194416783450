import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AppWrapper from './AppWrapper';
import { useReactToPrint } from 'react-to-print';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { AppProvider } from './AppContext';
import { PrintComponent } from './Components/Print';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/sk';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import { skSK } from '@mui/material/locale';
import { Grow } from '@mui/material';

import SuccessAlert from './Alerts/Success';
import ErrorAlert from './Alerts/Error';
import WarningAlert from './Alerts/Warning';
import InfoAlert from './Alerts/Info';

ReactDOM.render(
  <AppProviderWithNotistack />
  ,
  document.getElementById('root')
);


function AppProviderWithNotistack(props) {
  let componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Preberací lístok",
    copyStyles: false,
    pageStyle: `
        @page {
            /* Remove browser default header (title) and footer (url) */
            font-family: unset !important;
            margin: 0;
        }
        @media print {
            body {
              font-family: unset !important;
              /* Tell browsers to print background colors */
              -webkit-print-color-adjust: exact; /* Chrome/Safari/Edge/Opera */
              color-adjust: exact; /* Firefox */
            }
        }
    `,
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: '#388e3c',
      },
      secondary: {
        main: '#42a5f5',
      },
      error: {
        main: '#c62828',
      },
      warning: {
        main: '#ff6f00',
      },
      info: {
        main: '#1565c0',
      },
      success: {
        main: '#4caf50',
      }
    },
  }, skSK);



  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        action={(key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        Components={{
          success: SuccessAlert,
          error: ErrorAlert,
          warning: WarningAlert,
          info: InfoAlert,
        }}
        TransitionComponent={Grow}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="sk">
          <AppProvider {...props} handlePrint={handlePrint}>
            <AppWrapper />
            <div style={{ display: 'none' }} id="print-component"><PrintComponent ref={componentRef} /></div>
          </AppProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
