import React from 'react'
import { withAllContexts } from '../../AllContextsWrapper';
import { AppBar, Dialog, Typography, Toolbar, IconButton, Slide, Box } from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';


class FinancesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
      allReservations: [],
      dateReservations: [],
      yearSelected: "",
      dateFrom: "",
      dateTo: ""
    };
  }

  closeModal = () => {
    window.history.back();
  }

  getPlaces = async () => {

    this.props.socketIO.socket.emit('places:getAll', (response) => {
      if (response.success === true) {
        this.setState({
          places: response.data
        })
      } else {
        this.setState({
          places: []
        })
      }
    });
  }

  getPriceForPlace = (place) => {
    let data = this.state.places;
    for (let i = 0; i < data.length; i++) {
      if (data[i].name === place) {
        return data[i].price;
      }
    }

    return 0;
  }

  getYearData = async () => {
    let from = this.state.yearSelected + "-01-01";
    let to = this.state.yearSelected + "-12-31";

    this.props.socketIO.socket.emit('reservations:getBetween', from, to, (response) => {
      if (response.success === true) {
        this.setState({
          allReservations: response.data
        })
      } else {
        this.setState({
          allReservations: []
        })
      }
    });
  }

  datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  handleClose = () => {
    window.history.back();
  }

  yearSelected = (year) => {
    this.setState({
      yearSelected: year
    },
      () => {
        this.getYearData();
        this.getPlaces();
      }
    )
  }

  getReservationsForDates = async () => {
    if (this.state.dateFrom && this.state.dateTo) {

      let from = this.state.dateFrom;
      let to = this.state.dateTo;

      this.props.socketIO.socket.emit('reservations:getBetween', from, to, (response) => {
        if (response.success === true) {
          this.setState({
            dateReservations: response.data
          })
        } else {
          this.setState({
            dateReservations: []
          })
        }
      });
    }
  }

  handleDateChange = (value, name) => {
    if (name === 'date_to') {
      this.setState({
        dateTo: value
      },
        () => {
          this.getReservationsForDates();
        }
      )
    } else {
      this.setState({
        dateFrom: value,
        dateTo: value
      })
    }
  }

  componentDidMount() {
    // this.getClients();
  }

  render() {
    let reservations = this.state.allReservations;
    let received = 0;
    let expected = 0;


    if (reservations !== []) {
      reservations.forEach((reservation, i) => {
        if (reservation.status === 'finished') {
          reservation.payments.forEach((payment, p) => {
            received = received + payment.amount;
          })

        } else if (reservation.status !== 'canceled') {
          let startDate = new Date(reservation.start_date);
          let endDate = new Date(reservation.end_date);
          let reservationLength = this.datediff(startDate, endDate) + 1;

          let canoePricesSingleDay = {
            1: 18,
            2: 18,
            3: 18,
            4: 18,
            5: 20,
            6: 20,
            7: 20,
            holiday: 20
          }

          let canoePricesMoreDays = {
            1: 14,
            2: 14,
            3: 14,
            4: 14,
            5: 15,
            6: 15,
            7: 15,
            holiday: 15
          }

          let canoePrice = 0;
          //let isInformationOnly = false;
          let kanoeCount = reservation.kanoe_count;
          if (kanoeCount === 0) {
            kanoeCount = reservation.kanoe_count_info_max;
            //isInformationOnly = true;
          }

          if (reservationLength === 1) {
            let date = new Date(reservation.start_date);
            let holidays = this.props.app.functions.getHolidays({ year: date.getFullYear() });
            let dayInWeek = date.getDay();
            dayInWeek = dayInWeek === 0 ? 7 : dayInWeek;
            let formatedDate = this.props.app.functions.formatDate(date);

            if (holidays.includes(formatedDate)) {
              canoePrice = canoePricesSingleDay['holiday'] * kanoeCount;
            } else {
              canoePrice = canoePricesSingleDay[dayInWeek] * kanoeCount;
            }

          } else if (reservationLength > 1) {
            for (var j = 0; j < reservationLength; j++) {
              let date = new Date(reservation.start_date);
              date.setDate(date.getDate() + j);
              let dayInWeek = date.getDay() === 0 ? 7 : date.getDay();
              dayInWeek = dayInWeek === 0 ? 7 : dayInWeek;
              let holidays = this.props.app.functions.getHolidays({ year: date.getFullYear() });
              let formatedDate = this.props.app.functions.formatDate(date);

              if (holidays.includes(formatedDate)) {
                canoePrice = canoePrice + canoePricesMoreDays['holiday'] * kanoeCount;
              } else {
                canoePrice = canoePrice + canoePricesMoreDays[dayInWeek] * kanoeCount;
              }
            }
          }

          expected = expected + this.getPriceForPlace(reservation.start_place);
          expected = expected + this.getPriceForPlace(reservation.end_place);
          expected = expected + canoePrice;
        }
      })
    }

    return (
      <Dialog
        open={this.props.app.state.shownComponent === 'finances'}
        onClose={this.handleClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="flex-grow">
              Financie
            </Typography>
          </Toolbar>
        </AppBar>
        <Box p={2}>
          <Box mb={2}>
            <Typography variant="h6">
              Ročný súhrn
            </Typography>

            <select value={this.state.yearSelected} onChange={(e) => this.yearSelected(e.target.value)}>
              <option value="">Vybrať rok</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
            {
              this.state.yearSelected ?

                <div className="fin-total-inner">
                  <div className="fin-total-table_wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Prijaté</th>
                          <th>Odhad za nevybavené</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{received} <span>€</span></td>
                          <td>{expected} <span>€</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                :
                ""
            }
          </Box>

          <Box mb={2}>


            <div className="fin-calculate_wrapper">
              <h4>Kalkulačka</h4>
              <div className="fin-cal-inputs">
                <label>Od<input type="date" name="date_from" value={this.state.dateFrom} onChange={(e) => this.handleDateChange(e.target.value, e.target.name)} /></label>
                <label>Do<input type="date" name="date_to" min={this.state.dateFrom} value={this.state.dateTo} onChange={(e) => this.handleDateChange(e.target.value, e.target.name)} /></label>
              </div>

              <div className="fin-cal-total_wrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th colSpan="2">Prepravy</th>
                      <th colSpan="2">Iné poplatky</th>
                      <th></th>
                      <th colSpan="2">Prijal</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>Rezerácia</th>
                      <th>Peťo ml.</th>
                      <th>Peťo st.</th>
                      <th>Peťo ml.</th>
                      <th>Peťo st.</th>
                      <th>Táborisko</th>
                      <th>Peťo ml.</th>
                      <th>Peťo st.</th>
                      <th>Kanoe</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Box>

        </Box>
      </Dialog>

    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default withAllContexts(FinancesModal);
