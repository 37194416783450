import React from 'react'
import AddFee from './AddFee';
import { confirmAlert } from 'react-confirm-alert';
import { withAllContexts } from '../../../AllContextsWrapper';
import { enqueueSnackbar } from 'notistack';
import { Box, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material';

class Fees extends React.Component {

  deleteFeeConfirmation = (feeId) => {
    confirmAlert({
      title: 'Zmazanie poplatku',
      message: 'Naozaj chceš vymazať tento poplatok?',
      buttons: [
        {
          label: 'Zrušiť'
        },
        {
          label: 'Zmazať',
          onClick: () => this.deleteFee(feeId)
        }
      ]
    });
  }

  deleteFee = (feeId) => {

    this.props.socketIO.socket.emit('fee:delete', feeId, (response) => {
      if (response.success === true) {
        this.props.app.functions.updateCurrReservation(this.props.reservation.id);
        enqueueSnackbar("Poplatok úspešne odstránený", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Poplatok sa nepodarilo odstrániť", {
          variant: "error",
        });
      }
    });

  }

  render() {
    let reservation = this.props.reservation
    let fees = reservation.fees;
    console.log(fees)
    if (fees.length) {
      return (
        <Box sx={{ width: '100%' }}>
          <TableContainer component={Paper} elevation={3} sx={{ mb: 2 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Kategória</TableCell>
                  <TableCell>Popis</TableCell>
                  <TableCell>Množstvo</TableCell>
                  <TableCell>Jedn. Cena</TableCell>
                  <TableCell>Cena spolu</TableCell>
                  <TableCell>Pridal</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fees.map((fee, key) => (
                  <TableRow
                    key={key + "_fee"}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{this.props.app.functions.getDisplayText(fee.category)}</TableCell>
                    <TableCell>{fee.description}</TableCell>
                    <TableCell>{fee.amount}</TableCell>
                    <TableCell>{fee.price} €</TableCell>
                    <TableCell>{fee.price * fee.amount} €</TableCell>
                    <TableCell>{this.props.socketIO.usersShort[fee.added_by]}</TableCell>
                    <TableCell>
                      <IconButton aria-label="delete" onClick={(e) => this.deleteFeeConfirmation(fee.id)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AddFee
            reservation={this.props.reservation}
          />
        </Box>
      )
    } else {
      return (
        <Box sx={{ width: '100%' }}>
          <Paper elevation={3} sx={{ mb: 2 }}>
            <Typography variant="h6" component="div" sx={{ p: 2 }}>
              Zatiaľ žiadne poplatky
            </Typography>
          </Paper>
          <AddFee
            reservation={this.props.reservation}
          />
        </Box>
      )
    }
  }
}


export default withAllContexts(Fees);
