import React from 'react'
import { withAllContexts } from '../../AllContextsWrapper';
import EditClient from './Client/EditClient';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { AppBar, Dialog, Typography, Toolbar, IconButton, Slide, Box, TextField, CircularProgress } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import {
  Close as CloseIcon,
  ModeEditOutlined as ModeEditOutlinedIcon,
} from '@mui/icons-material';



class ClientsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: null,
      editClient: null,
      search: ""
    };
  }

  closeModal = () => {
    window.history.back();
  }

  getClients = (resolve, reject) => {
    if(!resolve) resolve = () => {};
    if(!reject) reject = () => {};

    this.props.socketIO.socket.emit('client:getAll', (response) => {
      if (response.success === true) {
        this.setState({
          clients: response.data
        }, ()=> {
          resolve();
        })
      } else {
        this.setState({
          clients: null
        }, () => {
          reject();
        })
      }
    });
  }

  closeEdit = () => {
    this.setState({ editClient: null });
    this.getClients();
    window.history.back();
  }

  editClient = (client) => {
    this.setState({ editClient: client });
    this.props.app.functions.pushHistoryState(`/clients/${client.id}`);
  }

  afterOpenModal = () => {
    this.getClients();
  }

  componentDidMount() {
    this.getClients();
  }

  handlePullToRefresh = () => {
    return new Promise((resolve, reject) => {
      this.getClients(resolve, reject);
    });
  }

  render() {
    let clients = this.state.clients;
    if (this.state.search.length > 0) {
      clients = clients.filter(
        o => {
          return Object.keys(o).some(k => {
            if (o[k] !== null && k !== "id")
              return o[k].toLowerCase().includes(this.state.search.toLowerCase())

            return false;
          })
        }
      )
    }

    return (
      <Dialog
        fullScreen
        open={this.props.app.state.shownComponent === 'clients'}
        onClose={this.closeModal}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.closeModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {this.state.editClient ? "Upraviť klienta" : "Zoznam klientov"}
            </Typography>
          </Toolbar>
        </AppBar>

        <PullToRefresh
          onRefresh={this.handlePullToRefresh}
          pullDownThreshold={70}
          maxPullDownDistance={80}
          resistance={3}
          pullingContent=""
          refreshingContent={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <CircularProgress size={40} sx={{ color: 'primary.main', mt: 2, mb: 2 }} />
            </Box>
          }
        >
        <Box sx={{ p: 2, height: '100%', width: '100%' }}>
          {
            this.state.editClient ?

              <EditClient
                client={this.state.editClient}
                closeEdit={this.closeEdit}
              />
              : (

                <Box sx={{ p: 2, height: '100%', width: '100%' }}>
                  <TextField
                    autoFocus
                    label="Hľadať"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                  />
                  <Box sx={{ height: '90%', width: '100%', mt: 2 }}>
                    <DataGrid
                      rows={clients}
                      columns={[
                        { field: 'id', headerName: 'ID', width: 70, disableColumnMenu: true },
                        { field: 'name', headerName: 'Meno', disableColumnMenu: true, minWidth: 200, flex: 1 },
                        { field: 'phone', headerName: 'Telefón', disableColumnMenu: true, minWidth: 200, flex: 1 },
                        { field: 'email', headerName: 'E-mail', disableColumnMenu: true, minWidth: 200, flex: 1 },
                        {
                          field: 'edit',
                          headerName: '',
                          width: 70,
                          disableColumnMenu: true,
                          sortable: false,
                          renderCell: (params) => (
                            <IconButton
                              color="info"
                              aria-label="edit"
                              onClick={(e) => this.editClient(params.row)}
                            >
                              <ModeEditOutlinedIcon />
                            </IconButton>
                          )
                        }
                      ]}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 25,

                          },
                        },
                      }}
                      disableSelectionOnClick
                    />
                  </Box>
                </Box>
              )
          }
        </Box>
        </PullToRefresh>
      </Dialog>

    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default withAllContexts(ClientsModal);
