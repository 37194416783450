import React from 'react'
import ReservationModal from './Modals/Reservation';
import ClientsModal from './Modals/Clients';
import FinancesModal from './Modals/Finances';
import { withAllContexts } from '../AllContextsWrapper';


class Modals extends React.Component {

  render() {
    return (
      <div className="modals-wrapper">
        <ReservationModal />
        <ClientsModal />
        <FinancesModal />
      </div>
    );
  }
}

export default withAllContexts(Modals);
