import React from "react";
import { withAllContexts } from "../../AllContextsWrapper";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import {
  CalendarViewWeekOutlined as CalendarViewWeekOutlinedIcon,
  CalendarViewMonthOutlined as CalendarViewMonthOutlinedIcon,
  CalendarViewDayOutlined as CalendarViewDayOutlinedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  ViewWeekOutlined as ViewWeekOutlinedIcon,
} from "@mui/icons-material";

class RangeSelect extends React.Component {

  render() {

    return (
      <Box
        edge="end"
        sx={{ display: { xs: 'none', md: 'block' }, ml: 'auto' }}
      >
        <ToggleButtonGroup
          size="small"
          value={this.props.app.state.view}
          sx={{ backgroundColor: 'white' }}
          exclusive
          onChange={(e, value) => {
            this.props.app.functions.setViewType(value);
            this.props.app.functions.pushHistoryState();
            localStorage.setItem('view', value);
          }}
        >
          <ToggleButton value="month"><CalendarMonthOutlinedIcon /></ToggleButton>
          <ToggleButton value="2weeks"><CalendarViewMonthOutlinedIcon /></ToggleButton>
          <ToggleButton value="week"><CalendarViewWeekOutlinedIcon /></ToggleButton>
          <ToggleButton value="3days"><ViewWeekOutlinedIcon /></ToggleButton>
          <ToggleButton value="1day"><CalendarViewDayOutlinedIcon /></ToggleButton>
        </ToggleButtonGroup>
      </Box>
    );
  }
}

export default withAllContexts(RangeSelect);
