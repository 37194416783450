import React from 'react'
import { withAllContexts } from '../../../AllContextsWrapper';
import { Box, Grid, TextField, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

class EditClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        ...this.props.client
      }
    };
  }

  updateFormState = (value, name) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }))
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();

    this.props.socketIO.socket.emit('client:update', this.state.form, (response) => {
      if (response.success === true) {
        this.props.app.functions.updateMainCalendar();
        this.props.closeEdit();
        enqueueSnackbar("Klient úspešne aktualizovaný", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Klienta sa nepodarilo aktualizovať", {
          variant: "error",
        });
      }
    });
  }

  render() {

    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Meno"
              name="name"
              value={this.state.form.name}
              onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Telefón"
              name="phone"
              value={this.state.form.phone}
              onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="E-mail"
              name="email"
              value={this.state.form.email}
              onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={(e) => this.handleFormSubmit(e)}
            >
              Uložiť
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withAllContexts(EditClient);
