import React from 'react';
import { ReactComponent as Logo } from './Assets/svg/logo.svg';
import { withAllContexts } from './AllContextsWrapper';
import {  Box, Paper, Grid, TextField, Button } from '@mui/material';

class Login extends React.Component {

  apiUrl = process.env.REACT_APP_API_URL;

  handleLogin = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    console.log("authenticate", email, password);

    fetch(this.apiUrl + "login", {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })

      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log("data", data);
        this.props.socketIO.socket.disconnect();
        this.props.socketIO.socket.connect();

        if (data.status) {
          this.props.socketIO.socket.emit('get_user_data');
        } else {
          console.log("login_error", data.error);
        }
      }
      );
  };


  render() {

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item sx={{ p: '25px' }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <form onSubmit={(e) => this.handleLogin(e)} style={{ padding: '10px' }}>
              <Grid container spacing={2}>
                <Grid item container>
                  <Box sx={{ m: 'auto' }}>
                    <Logo style={{ width: '200px' }} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Heslo"
                    type="password"
                    name="password"
                    autoComplete="current-password"
                  />
                </Grid>
              </Grid>
              <Button
                sx={{ mt: 2 }}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Prihlásiť
              </Button>
            </form>
          </Paper >
        </Grid>
      </Grid >
    )
  }
};

export default withAllContexts(Login);
