import React from 'react'
import { withAllContexts } from '../AllContextsWrapper';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';


class NavCalendar extends React.Component {

  changeMonth = (action) => {
    let navCalDate = new Date(this.props.app.state.navDate.date);
    navCalDate.setDate(navCalDate.getDate() + (+this.props.app.state.navDate.dim * action))
    this.props.app.functions.changeNavCalDate(this.props.app.functions.getDateObj(navCalDate), true);
  }


  render() {
    let actualDays = this.props.app.functions.actualDays(this.props.app.state.navDate);
    // let view = this.props.view;
    let fdi = this.props.app.functions.getFirstDayIndex(this.props.app.state.navDate);
    let days = [];

    for (let i = 0; i < this.props.app.functions.toShow(this.props.app.state.navDate, "month").days; i++) {
      let currDay = i + fdi;
      days.push(
        <NavCalendarDay
          {...this.props}
          key={i + "navCalendarDay"}
          date={actualDays[currDay]}
          today={this.props.today}
          holidays={this.props.app.functions.getHolidays(this.props.app.state.navDate)}
        />
      );
    }

    return (
      <Box
        sx={{ p: 2 }}
        className="embedded_calendar"
        type="nav"
      >
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, mt: '4px', ml: '14px' }}>
              {this.props.app.state.navDate.date.toLocaleString('default', { month: 'long' })}&nbsp;
              {this.props.app.state.navDate.year}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => this.changeMonth(-1)}
              size="medium"
            >
              <ArrowBackIosNewIcon />
            </IconButton >
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => this.changeMonth(1)}
              size="medium"
            >
              <ArrowForwardIosIcon />
            </IconButton >
          </Grid>
        </Grid>
        <Box
          className="ec_days_wrapper"
          sx={{ mt: 2 }}
        >
          <Box
            className="ec_days ec_days_names"
          >
            <Typography variant="caption" component="span">
              PO
            </Typography>
            <Typography variant="caption" component="span">
              UT
            </Typography>
            <Typography variant="caption" component="span">
              ST
            </Typography>
            <Typography variant="caption" component="span">
              ŠT
            </Typography>
            <Typography variant="caption" component="span">
              PI
            </Typography>
            <Typography variant="caption" component="span">
              SO
            </Typography>
            <Typography variant="caption" component="span">
              NE
            </Typography>
          </Box>
          <Box
            className="ec_days ec_days_current_month"
          >
            {days}
          </Box>
        </Box>

      </Box>

    );
  }
}

class NavCalendarDay extends React.Component {

  changeDate = () => {
    let dateObj = this.props.app.functions.getDateObj(this.props.date.date);
    this.props.app.functions.changeCalDate(dateObj);
  }

  render() {
    let classNames = "";
    if (this.props.today.dateStr === this.props.date.dateStr) {
      classNames = classNames + "ec_day_today ";
    }
    if (this.props.app.functions.getHolidays(this.props.app.state.navDate).includes(this.props.date.dateStr)) {
      classNames = classNames + "ec_day_holiday ";
    }

    return (
      <Typography variant="caption" component="span" onClick={this.changeDate} className={classNames}>
        <b>{this.props.date.day}</b>
      </Typography>
    );
  }
}

export default withAllContexts(NavCalendar);
