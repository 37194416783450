import React from 'react'
import { withAllContexts } from '../AllContextsWrapper';
import { Box, Backdrop, CircularProgress, BottomNavigation, BottomNavigationAction, Typography, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import PullToRefresh from 'react-simple-pull-to-refresh';
import ReactHoldButton from "react-longpressable";
import {
  ArrowCircleLeft as ArrowCircleLeftIcon,
  ArrowCircleRight as ArrowCircleRightIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  FormatListBulleted as FormatListBulletedIcon,
  AddShoppingCart as AddShoppingCartIcon,
  Euro as EuroIcon,
  Edit as EditIcon,
  History as HistoryIcon
} from '@mui/icons-material';


class Calendar extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    window.addEventListener("focus", (e) => this.getEvents());
    this.getEvents();
  }

  componentWillUnmount() {
    window.removeEventListener("focus", (e) => this.getEvents());
  }

  componentDidUpdate(prevProps) {
    if (this.props.date.dateStr !== prevProps.date.dateStr) {
      this.getEvents();
    }

    if (this.props.updateCalendar !== prevProps.updateCalendar) {
      this.getEvents();
    }
  }

  changeDate = (action) => {
    let newDate = new Date(this.props.app.state.date.date);
    if (this.props.view === "month") {
      let dim = new Date(this.props.date.year, this.props.date.month + action, 0).getDate()
      newDate.setDate(newDate.getDate() + (dim * action))
    } else if (this.props.view === "2weeks" || this.props.view === "week") {
      newDate.setDate(newDate.getDate() + (7 * action))
    } else {
      newDate.setDate(newDate.getDate() + (1 * action))
    }
    let newDateObj = this.props.app.functions.getDateObj(newDate);

    this.props.app.functions.changeNavCalDate(newDateObj);
    this.props.app.functions.changeCalDate(newDateObj, true);
  }

  datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  // (event, row) x (start, end)
  daysOverlap = (es, ee, rs, re) => {
    let ol = Math.min(ee, re) - Math.max(es, rs) + 1;
    return Math.max(ol, 0);
  }

  getWeekNumber = (date) => {
    var d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  }

  handleReservationHover = (reservationID) => {
    this.setState({
      hoverServervationId: reservationID
    })
  }

  processEvents = (eventsApi, firstDay, firstDayIndex, dayCount) => {

    const lastDayIndex = firstDayIndex + dayCount - 1;
    let events = [];

    for (let di = firstDayIndex; di <= lastDayIndex; di++)
      events[di] = [];
    for (let i = 0; i < eventsApi.length; i++) {
      const eventApi = eventsApi[i];
      let startDate = new Date(eventApi.start_date);
      let endDate = new Date(eventApi.end_date);

      // endDate = new Date(endDate); //date + 1day
      // endDate.setDate(endDate.getDate() - 1);

      let startDayIndex = firstDayIndex + this.datediff(firstDay, startDate);
      let endDayIndex = firstDayIndex + this.datediff(firstDay, endDate);

      if (endDayIndex < firstDayIndex || startDayIndex > lastDayIndex)
        continue;

      // let eventLenght = endDayIndex - startDayIndex + 1;
      let apparentLength = this.daysOverlap(startDayIndex, endDayIndex, firstDayIndex, lastDayIndex);

      let event = {
        ...eventApi,
        apparentLength,
        startDayIndex,
        endDayIndex
      };

      for (let currDayIndex = startDayIndex; currDayIndex <= endDayIndex; currDayIndex++) {
        if (currDayIndex < firstDayIndex)
          continue;

        if (currDayIndex > lastDayIndex)
          break;

        events[currDayIndex].push(event);
      }
    }

    return events;
  }

  getEvents = (resolvePromise, rejectPromise) => {
    if (!resolvePromise) resolvePromise = () => { };
    if (!rejectPromise) rejectPromise = () => { };

    let actualDays = this.props.app.functions.actualDays(this.props.app.state.date);
    let daysToShow = this.props.app.functions.toShow(this.props.app.state.date, this.props.app.state.view).days;
    let fdi = this.props.app.functions.getFirstDayIndex(this.props.app.state.date, this.props.app.state.view);
    let ldi = fdi + daysToShow;
    let from = actualDays[fdi].dateStr;
    let to = actualDays[ldi].dateStr;

    this.setState({
      isLoading: true
    });

    this.props.socketIO.socket.emit('reservations:getBetween', from, to, (response) => {
      if (response.success) {
        this.setState({
          reservations: response.data,
          isLoading: false
        }, () => {
          resolvePromise();
        })
      } else {
        this.setState({
          reservations: [],
          isLoading: false
        }, () => {
          rejectPromise();
        })
      }
    });
  }

  pullToRefreshGetEvents = () => {
    return new Promise((resolve, reject) => {
      this.getEvents(resolve, reject);
    })
  }


  render() {
    let actualDays = this.props.app.functions.actualDays(this.props.app.state.date);
    let days = [];
    let daysToShow = this.props.app.functions.toShow(this.props.app.state.date, this.props.app.state.view).days;
    let fdi = this.props.app.functions.getFirstDayIndex(this.props.app.state.date, this.props.app.state.view);
    let dayNames = [];
    let firstDayShown = actualDays[fdi];

    for (let i = 0; i < this.props.app.functions.toShow(this.props.app.state.date, this.props.app.state.view).row; i++) {
      let date = new Date(firstDayShown.date); //date + 1day
      date.setDate(date.getDate() + i);
      let dayName = date.toLocaleString('default', { weekday: 'long' });
      dayNames.push(dayName);
    }

    if (this.state.reservations) {
      let processedReservations = this.processEvents(this.state.reservations, actualDays[fdi].date, fdi, daysToShow);
      let prevDayReservations = [];

      for (let i = 0; i < daysToShow; i++) {
        let currDayIndex = i + fdi;
        let currDayDisplayIndex = i;

        let dayReservations = processedReservations[currDayIndex];
        let dayReservationsSlots = [];
        // dayReservations = dayReservations.filter(ev =>
        //   !prevDayReservations.some(pev => pev.id === ev.id)
        // );

        dayReservations = dayReservations.sort((a, b) => b.apparentLength - a.apparentLength);
        dayReservations = prevDayReservations.concat(dayReservations);

        if (this.props.view === "3days" || this.props.view === "1day") {

        }

        for (let reservation of dayReservations) {
          if (reservation.slot !== undefined) {
            dayReservationsSlots[reservation.slot] = reservation;
            continue;
          }

          let trySlot = 0;
          while (true) {
            if (dayReservationsSlots[trySlot]) {
              trySlot++;
              continue;
            }
            dayReservationsSlots[trySlot] = reservation;
            reservation.slot = trySlot;
            break;
          }
        }

        days.push(<CalendarDay
          {...this.props}
          key={i + "calendarDay"}
          date={actualDays[currDayIndex]}
          reservations={this.state.isLoading ? [] : dayReservationsSlots}
          currDayDisplayIndex={currDayDisplayIndex}
          currDayIndex={currDayIndex}
          view={this.props.view}
          toShow={this.props.app.functions.toShow(this.props.app.state.date, this.props.app.state.view)}
          holidays={this.props.app.functions.getHolidays(this.props.app.state.date)}
          today={this.props.today}
          handleReservationHover={this.handleReservationHover}
          hoverServervationId={this.state.hoverServervationId} //TODO fix typo
        />);
        prevDayReservations = dayReservations.filter(e => e.endDayIndex > currDayIndex);
      }
    }

    return (
      <>
        <Box
          className="embedded_calendar"
          sx={{ pb: 7 }}
          type="main"
          view={this.props.view}
          weeks-to-show={this.props.app.functions.toShow(this.props.app.state.date, this.props.app.state.view).weeks}
          days-to-show={this.props.app.functions.toShow(this.props.app.state.date, this.props.app.state.view).days}
        >
          <PullToRefresh
            onRefresh={this.pullToRefreshGetEvents}
            pullDownThreshold={70}
            maxPullDownDistance={80}
            resistance={3}
            pullingContent=""
            refreshingContent={
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <CircularProgress size={40} sx={{ color: 'primary.main', mt: 2, mb: 2 }} />
              </Box>
            }
          >
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Box className="ec_days_wrapper">
              <Box className="ec_days ec_days_names ec_full_week">
                {dayNames.map((d, i) => (
                  <Typography key={i} variant="body2" component="span" sx={{ fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center' }}>
                    {d}
                  </Typography>
                ))}
              </Box>
              <Box className="ec_days ec_days_current_month">
                {days}
              </Box>
            </Box>
          </PullToRefresh>
        </Box>
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <BottomNavigation
            //value={value}
            sx={{ borderTop: '1px solid #e0e0e0' }}
            onChange={(event, newValue) => {
              if (newValue === 0) {
                this.changeDate(-1)

              } else if (newValue === 2) {
                this.changeDate(1);

              } else {
                console.log(this.props.app.state.shownComponent);
                if (this.props.app.state.shownComponent !== 'navCal')
                  this.props.app.functions.setShownComponent('navCal');
                else
                  this.props.app.functions.setShownComponent(null);
              }
            }}
          >
            <BottomNavigationAction label="Predchádzajúci" icon={<ArrowCircleLeftIcon color="secondary" />} />
            <BottomNavigationAction label="Aktuálne" {...this.props} component={CurrentViewText} />
            <BottomNavigationAction label="Ďaľší" icon={<ArrowCircleRightIcon color="secondary" />} />
          </BottomNavigation>
        </Box>
      </>
    );
  }
}


class CurrentViewTextBase extends React.Component {

  getLocaleString = (date) => {
    return date.toLocaleString('default', {
      month: 'long'
    })
  }

  render() {

    let actualDays = this.props.app.functions.actualDays(this.props.app.state.date);
    let daysToShow = this.props.app.functions.toShow(this.props.app.state.date, this.props.app.state.view).days;
    let fdi = this.props.app.functions.getFirstDayIndex(this.props.app.state.date, this.props.app.state.view);
    let ldi = fdi + daysToShow;
    let firstDayShown = actualDays[fdi];
    let lastDayShown = actualDays[ldi];

    let monthText = "";
    let yearText = "";

    if (firstDayShown && lastDayShown) {
      if (this.props.app.state.view === 'month') {
        let firstDayDate = new Date(firstDayShown.date); //date + 1day
        firstDayDate.setDate(firstDayDate.getDate() + 10);
        monthText = this.getLocaleString(firstDayDate);

      } else if (this.props.app.state.view === '1day') {
        monthText = firstDayShown.day + '. ' + this.getLocaleString(firstDayShown.date);

      } else {

        if (firstDayShown.year !== lastDayShown.year) {
          monthText = +firstDayShown.day + '. ' + this.getLocaleString(firstDayShown.date) + '. ' + firstDayShown.year + ' - ' + +lastDayShown.day + '. ' + this.getLocaleString(lastDayShown.date) + '. ' + lastDayShown.year;
        } else {
          monthText = +firstDayShown.day + '. ' + this.getLocaleString(firstDayShown.date) + ' - ' + +lastDayShown.day + '. ' + this.getLocaleString(lastDayShown.date);
        }
      }
    }

    if (firstDayShown.year === lastDayShown.year) {
      yearText = firstDayShown.year;
    }
    let { app, socketIO, forwardedRef, updateCalendar, ...other } = this.props;

    return (
      <Box
        ref={this.props.forwardedRef}
        {...other}
      >
        <Box className="ec_current_view_text">
          <Box className="ec_current_view_text_month">{monthText}</Box>
          <Box className="ec_current_view_text_year">{yearText}</Box>
          {this.props.children}
        </Box>
      </Box>
    );
  }
}
const CurrentViewText = React.forwardRef((props, ref) => <CurrentViewTextBase {...props} forwardedRef={ref} />);



class CalendarDay extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      reservationHoverShownId: 0,
      anchorEl: null,
    };
  }

  // (event, row) x (start, end)
  daysOverlap = (es, ee, rs, re) => {
    let ol = Math.min(ee, re) - Math.max(es, rs) + 1;
    return Math.max(ol, 0);
  }


  datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  handleReservationHoverTimeout = null;

  handleReservationHover = (reservationId) => {
    this.props.handleReservationHover(reservationId);

    //TODO: ak chcem aby sa zobrazovali info o rezervácii, tak nejako upraviť aby to nebolo pomalé
    // this.setState({
    //   reservationHoverShownId: reservationId
    // })
    // if(reservationId === 0 && this.handleReservationHoverTimeout === null) {
    //   this.handleReservationHoverTimeout.clearTimeout();
    // } else {
    //   this.handleReservationHoverTimeout = setTimeout(() => {
    //     this.setState({
    //       reservationHoverShownId: reservationId
    //     })
    //   }, 1000);
    // }
  }

  handleReservationClick = (event, reservation) => {
    this.props.app.functions.updateCurrReservation(reservation.id, true)
  }

  handleReservationLongPress = (event, reservation) => {
    event.preventDefault();
    this.setState({
      anchorEl: event.target,
      menuReservation: reservation
    })
  }

  handleReservationDropdownMenuAction = (action) => {
    console.log('reservation dropdown menu action', action);
    let reservation = this.state.menuReservation;

    if (action === 'edit') {
      this.props.app.functions.updateCurrReservation(reservation.id, false, () => {
        this.props.app.functions.setCurrentlyEditing(true);
      });
    } else if (action === 'history') {
      this.props.app.functions.updateCurrReservation(reservation.id, true, () => {
        this.props.app.functions.setCurrentReservationTab(1);
      })
    } else if (action === 'messages') {
      this.props.app.functions.updateCurrReservation(reservation.id, true, () => {
        this.props.app.functions.setCurrentReservationTab(2);
      })
    } else if (action === 'equipment') {
      this.props.app.functions.updateCurrReservation(reservation.id, true, () => {
        this.props.app.functions.setCurrentReservationTab(3);
      })
    } else if (action === 'fees') {
      this.props.app.functions.updateCurrReservation(reservation.id, true, () => {
        this.props.app.functions.setCurrentReservationTab(4);
      })
    } else if (action === 'payments') {
      this.props.app.functions.updateCurrReservation(reservation.id, true, () => {
        this.props.app.functions.setCurrentReservationTab(5);
      })
    }

    this.handleCloseDropdownMenu();
  }


  handleCloseDropdownMenu = () => {
    this.setState({
      anchorEl: null,
      menuReservation: null
    })
  }


  render() {
    let dayReservarions = [];
    let kanoeCount = 0;
    let katCount = 0;

    if (this.props.reservations.length) {
      let daysInRow = this.props.toShow.row

      let currDayIndex = this.props.currDayIndex;
      let currRowIndex = this.props.currDayDisplayIndex % daysInRow;
      let isStartOfRow = currRowIndex === 0;
      // let isEndOfRow = currRowIndex === daysInRow - 1;
      let startOfRowIndex = currDayIndex - currRowIndex;
      let endOfRowIndex = startOfRowIndex + daysInRow - 1;

      let dayKey = this.props.currDayDisplayIndex;

      let reservationClassNames;
      let style = {}
      let text;
      let eventHover = null;



      for (const reservation of this.props.reservations) {
        text = "";
        style = {};
        reservationClassNames = "calendar-reservation";


        if (reservation && (reservation.startDayIndex === currDayIndex || isStartOfRow)) {
          let color;

          switch (reservation.status) {
            case 'onwater':
              color = 'hsl(224, 100%, 41%)';
              break;
            case 'canceled':
              color = 'hsl(0, 100%, 84%)';
              reservationClassNames = reservationClassNames + ' canceled';
              break;
            case 'finished':
              color = 'hsl(0, 5%, 67%)';
              break;
            case 'waitingforpayment':
              color = 'hsl(359, 100%, 50%)';
              break;
            default:
              color = reservation.color;
          }

          let backgroundColor = color.replace('hsl', 'hsla').replace(')', ', 0.4 )');

          style = {
            backgroundColor: backgroundColor,
            borderColor: color
          }
          let canoeCount = 0;
          if (reservation.kanoe_count > 0) {
            canoeCount = reservation.kanoe_count;
          } else if (reservation.kanoe_count_info_min === reservation.kanoe_count_info_max) {
            canoeCount = reservation.kanoe_count_info_min;
          } else {
            canoeCount = `${reservation.kanoe_count_info_min} - ${reservation.kanoe_count_info_max}`;
          }

          let katCount = reservation.katamaran_count_info ? `(${reservation.katamaran_count_info}kat)` : '';

          let clientText = reservation.client.name ?
            reservation.client.name :
            reservation.client.phone ?
              reservation.client.phone :
              reservation.client.email;



          text = `${canoeCount}ks ${katCount} ${clientText}`;

          if (isStartOfRow && reservation.startDayIndex < currDayIndex)
            reservationClassNames = reservationClassNames + ' reservation_start_week_before';

          if (reservation.endDayIndex > endOfRowIndex)
            reservationClassNames = reservationClassNames + ' reservation_continuing';

          let rowEventLength = this.daysOverlap(reservation.startDayIndex, reservation.endDayIndex, startOfRowIndex, startOfRowIndex + daysInRow - 1);
          reservationClassNames = reservationClassNames + ' length-' + rowEventLength;

          //let startDate = new Date(reservation.start_date);
          //let endDate = new Date(reservation.end_date);
          //let reservationLength = this.datediff(startDate, endDate) + 1;
          //let dateText = startDate.toLocaleDateString().replace(/\s/g, "");

          let isHover = this.props.hoverServervationId === reservation.id ? ' hover' : '';
          reservationClassNames = reservationClassNames + isHover;

          /* eventHover = (
            <div className={`reservation-hover ${this.state.reservationHoverShownId === reservation.id ? 'shown' : ''}`}>

              <div className="columns">
                <div className="column">
                  <table>
                    <tbody>
                      <tr>
                        <td>Dĺžka splavu</td>
                        <td>{reservationLength}</td>
                      </tr>
                      <tr>
                        <td>Termín</td>
                        <td>{dateText}</td>
                      </tr>
                      <tr>
                        <td>Počet kanoe</td>
                        <td>{reservation.kanoe_count_info_max}</td>
                      </tr>
                      <tr>
                        <td>Počet katamaránov</td>
                        <td>{reservation.katamaran_count_info}</td>
                      </tr>
                      {reservation.start_place !== "" ?
                        <tr>
                          <td>Štart</td>
                          <td>{reservation.start_place}</td>
                        </tr>
                        : ""
                      }
                      {reservation.end_place !== "" ?
                        <tr>
                          <td>Cieľ</td>
                          <td>{reservation.end_place}</td>
                        </tr>
                        : ""
                      }
                    </tbody>
                  </table>
                </div>
                <div className="column">
                  <table>
                    <tbody>
                      {reservation.client.name !== null ?
                        <tr>
                          <td>Meno</td>
                          <td>{reservation.client.name}</td>
                        </tr>
                        : ""
                      }
                      {reservation.client.phone !== null ?
                        <tr>
                          <td>Telefón</td>
                          <td><a href={`tel:${reservation.client.phone}`}>{reservation.client.phone}</a></td>
                        </tr>
                        : ""
                      }
                      {reservation.client.email !== null ?
                        <tr>
                          <td>E-mail</td>
                          <td><a href={`mailto:${reservation.client.email}`}>{reservation.client.email}</a></td>
                        </tr>
                        : ""
                      }
                    </tbody>
                  </table>
                </div>
              </div>

              {reservation.note ?
                <div className="note-wrapper">
                  <p>{reservation.note}</p>
                </div>
                : ""
              }
            </div>
          ); */


        } else {
          reservationClassNames = reservationClassNames + ' reservation-placeholder';
        }

        if (reservation && reservation.status !== 'canceled') {
          kanoeCount = kanoeCount + reservation.kanoe_count_info_max;
          katCount = katCount + reservation.katamaran_count_info;
        }

        if (reservation || isStartOfRow) {
          dayReservarions.push(
            <ReactHoldButton
              key={`${reservation?.id}_${dayKey}`}
              onLongPress={(event) => this.handleReservationLongPress(event, reservation)}
              onShortPress={(event) => this.handleReservationClick(event, reservation)}
              longPressTime={300}
            >
              <Box
                className={reservationClassNames}
                style={style}
                onMouseEnter={() => this.handleReservationHover(reservation?.id)}
                onMouseLeave={() => this.handleReservationHover(0)}
                onContextMenu={(event) => this.handleReservationLongPress(event, reservation)}
              >
                <Typography variant="body1">{text ? text : null}</Typography>
                {eventHover}
              </Box>
            </ReactHoldButton >
          );
        }
      }
    }

    let dayClassnames = "single-day ";
    if (this.props.today.dateStr === this.props.date.dateStr) {
      dayClassnames = dayClassnames + "ec_day_today ";
    }
    if (this.props.app.functions.getHolidays(this.props.app.state.date).includes(this.props.date.dateStr)) {
      dayClassnames = dayClassnames + "ec_day_holiday ";
    }

    return (
      <Box className={dayClassnames}>
        <Box className="day-params">
          <Box className="day-params-day" sx={{ pt: '3px' }}>
            <Typography variant="h6" className="day-day-name">{+this.props.date.day}</Typography>
          </Box>
          <Box className="day-params-data" sx={{ pt: '7px' }}>
            <Typography sx={{ display: 'inline' }} variant="body1">
              {+kanoeCount}ks&nbsp;
            </Typography>
            {katCount !== 0 &&
              <Typography sx={{ display: 'inline' }} variant="body1">
                ({+katCount}kat)
              </Typography>
            }
          </Box>
          <Box className="day-event-add_wrapper">
            <AddCircleOutlineIcon color="primary" className="day-event-add" onClick={() => this.props.app.functions.toggleAddNewReservation(this.props.date.date)} />
          </Box>
        </Box>
        <Box className="day-data-reservations">
          {dayReservarions}

          <Menu
            id="reservation-dropdown-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.anchorEl !== null}
            onClose={this.handleCloseDropdownMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => this.handleReservationDropdownMenuAction('edit')}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Upraviť</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.handleReservationDropdownMenuAction('history')}>
              <ListItemIcon>
                <HistoryIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>História</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.handleReservationDropdownMenuAction('messages')}>
              <ListItemIcon>
                <HistoryIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Správy</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.handleReservationDropdownMenuAction('equipment')}>
              <ListItemIcon>
                <FormatListBulletedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Výbava</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.handleReservationDropdownMenuAction('fees')}>
              <ListItemIcon>
                <AddShoppingCartIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Poplatky</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.handleReservationDropdownMenuAction('payments')}>
              <ListItemIcon>
                <EuroIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Platby</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    )
  }
}

export default withAllContexts(Calendar);
