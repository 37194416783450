import React, { useContext } from 'react';
import { io } from "socket.io-client";
import { Button } from "@mui/material";
import {
    enqueueSnackbar,
    closeSnackbar,
} from 'notistack';

const AppContext = React.createContext();
const socket = io(process.env.REACT_APP_WS_URL);


class AppProvider extends React.Component {
    snackbarID = null;
    lastReservationUpdated = null;
    qrScannerSetFunction = null;

    constructor(props) {
        super(props);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const todayObj = this.getDateObj(today);

        this.state = {
            app: {
                shownComponent: null, // 'sideMenu', 'event', 'addEvent', 'clients', 'finances', 'navCal'

                updateCalendar: false,
                reservation: null,
                isLoading: false,
                view: 'month',


                date: todayObj,
                navDate: todayObj,
                today: todayObj,
                addReservation: false,
                addReservationDate: null,
                isCurrentlyEditing: false,
                currentReservationTab: 0,

            },
            qrScanner: {
                active: false,
            },
            socketIO: {
                user: {},
                state: null, // connecting, connected, logging_in, logged_in
                isConnected: false,
                isLoggedIn: false,
                isWaiting: true,
                usersShort: [],
            },
        }

        window.socket = socket;


    }

    componentDidMount() {
        window.history.pushState(this.state.app, null, `/`);
        window.addEventListener("popstate", this.handlePopState);

        setTimeout(() => {
            this.setState({ socketIO: { ...this.state.socketIO, isWaiting: false } });
        }, 2000);

        socket.on('connect', () => {
            this.setState({ socketIO: { ...this.state.socketIO, isConnected: true } });
            console.log('context', this)
        });

        socket.on('disconnect', () => {
            this.setState({ socketIO: { ...this.state.socketIO, isConnected: false } });
        });

        socket.on("connect_error", (err) => {
            this.setState({ socketIO: { ...this.state.socketIO, isConnected: false } });
            console.log(`connect_error to ${process.env.REACT_APP_WS_URL} due to`, err);
        });

        socket.emit('get_user_data');

        socket.on('user_data', (data) => {
            console.log('user_data', data);
            this.setState({ socketIO: { ...this.state.socketIO, user: data, isLoggedIn: true, isWaiting: false } });
        });

        socket.emit('user:get_short_names_with_id', (response) => {
            if (response.success === true) {
                console.log('users:get_short_names_with_id', response.data);
                this.setState({ socketIO: { ...this.state.socketIO, usersShort: response.data } });
            }
        });

        socket.on('reservations:updated', (data) => {
            console.log('reservations:updated', data.reservationID, this.state.app.reservation);
            this.lastReservationUpdated = data.reservationID;
            if (this.state.app.reservation?.id === data.reservationID) {
                console.log('current reservation updated');
                if (this.state.isCurrentlyEditing) {
                    this.currentReservationWasUpdated();
                } else {
                    this.updateCurrentReservationAction();
                }
            }

            this.updateMainCalendar();
        });
    }


    initQRScanner = (setFunction) => {
        console.log('initQRScanner');
        this.qrScannerSetFunction = setFunction;
        this.setState({ qrScanner: { ...this.state.qrScanner, active: true } });
    }

    cancelQRScanner = () => {
        console.log('cancelQRScanner');
        this.qrScannerSetFunction = null;
        this.setState({ qrScanner: { ...this.state.qrScanner, active: false } });
    }

    setQRScannerResult = (result) => {
        console.log('cancelQRScanner', result);
        if (this.qrScannerSetFunction) {
            this.qrScannerSetFunction(result);
            this.cancelQRScanner();
        }
    }

    setCurrentReservationTab = (tab) => {
        this.setState({ app: { ...this.state.app, currentReservationTab: tab } });
    }

    currentReservationWasUpdated = () => {
        this.snackbarID = enqueueSnackbar("Táto rezervácia bola aktualizovaná", {
            persist: true,
            variant: "info",
            action: this.refreshAction(),
            preventDuplicate: true,
            key: "currentReservationWasUpdated"
        });
    }

    refreshAction = (key) => { //render the snackbar button
        return (
            <React.Fragment>
                <Button
                    className="snackbar-button"
                    size="small"
                    onClick={this.updateCurrentReservationAction}
                >
                    {"Obnoviť"}
                </Button>
            </React.Fragment>
        );
    };

    updateCurrentReservationAction = () => {
        if (this.snackbarID)
            closeSnackbar(this.snackbarID);

        if (this.lastReservationUpdated)
            this.updateCurrReservation(this.lastReservationUpdated, false);
    }

    getDisplayText = (str) => {
        let texts = {
            ordered: "Objednaná",
            onwater: "Na vode",
            canceled: "Zrušená",
            finished: "Ukončená",
            waitingforpayment: "Čaká na platbu",
            transport: "Preprava",
            camping: "Táborisko",
            other: "Iné",
            barrel_count: "Výbava: počet sudov",
            end_date: "Dátum ukončenia",
            start_date: "Dátum začiatku",
            end_place: "Miesto ukončenia",
            start_place: "Miesto začiatku",
            equipment_note: "Výbava: poznámka",
            note: "Poznámka",
            kanoe_count: "Výbava: počet kanoe",
            kanoe_count_info_max: "Počet kanoe (max)",
            kanoe_count_info_min: "Počet kanoe (min)",
            katamaran_count_info: "Počet katamaránov",
            katamaran_count: "Výbava: počet katamaránov",
            paddle_count: "Výbava: počet pádiel",
            status: "Stav",
            vest_count: "Výbava: počet viest",

        }

        return str in texts ? texts[str] : str;
    };

    getPaymentTypes = () => {
        return {
            cash: "Hotovosť",
            card: "Karta",
            invoice: "Faktúra",
            giftcard: "Darčekový poukaz",
            other: "Iné",
        };
    };

    getDateObj = (date) => {
        const dateStr = `${(date.getFullYear())}-${this.pad(((date.getMonth()) + 1), 2)}-${this.pad((date.getDate()), 2)}`;

        return {
            date: date,
            dateStr: dateStr,
            year: (date.getFullYear()),
            month: (date.getMonth() + 1),
            day: (date.getDate()),
            dim: (new Date((date.getFullYear()), (date.getMonth() + 1), 0).getDate())
        };
    };

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }


    actualDays = (dateObj) => {
        // TODO: instead of 3 months: 6 days + 1 month + 6 days
        let date = new Date(dateObj.year, dateObj.month - 2, 1);
        let lastDay = new Date(dateObj.year, dateObj.month + 1, 0);
        let actualDays = [];

        while (date <= lastDay) {
            let y = (date.getFullYear());
            let m = this.pad((date.getMonth() + 1), 2);
            let d = this.pad(date.getDate(), 2);
            let dateStr = y + "-" + m + "-" + d;

            actualDays.push({
                date: date,
                dateStr: dateStr,
                day: d,
                month: m,
                year: y,
                // diw: date.getDay() == 0 ? 7 : date.getDay()
            });

            date = new Date(date); //date + 1day
            date.setDate(date.getDate() + 1);
        }
        return actualDays;
    };

    handlePopState = (e) => {
        this.setState({ app: e.state });
    }

    findMonday(date) {
        var day = date.getDay(),
            diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        let mondayDate = new Date(date);
        mondayDate.setDate(diff);
        mondayDate.setHours(0, 0, 0, 0);

        return mondayDate;
    }

    setShownComponent = (shownComponent, pushToHistory) => {
        // 'sideMenu', 'event', 'addEvent', 'clients', 'finances', 'navCal'
        if (pushToHistory === undefined) {
            pushToHistory = false;
        }


        let url = '/';
        if (shownComponent === 'clients') {
            url = '/clients';
        } else if (shownComponent === 'finances') {
            url = '/finances';
        }

        this.setState({ app: { ...this.state.app, shownComponent } }, () => {
            if (pushToHistory) {
                this.pushHistoryState(url);
            }
        });
    }

    updateMainCalendar = () => {
        this.setState({ app: { ...this.state.app, updateCalendar: !this.state.app.updateCalendar } });
    }

    setViewType = (view) => {
        this.setState({ app: { ...this.state.app, shownComponent: null, view } }, () => this.pushHistoryState());
    }

    updateCurrReservation = (reservationId, pushState = false, callback = () => { }) => {
        this.setState({ app: { ...this.state.app, isLoading: true } });

        socket.emit('reservations:getById', reservationId, (response) => {
            console.log('reservations:getById', response)
            if (response.success) {

                this.setState({
                    app: {
                        ...this.state.app,
                        reservation: response.data,
                        reservationUpdated: null,
                        isLoading: false
                    }
                }, () => {
                    if (pushState) {
                        this.pushHistoryState(`/reservation/${reservationId}`);
                    }
                    callback();
                })
            } else {
                enqueueSnackbar('Rezerváciu sa nepodarilo načítať', { variant: 'error' });

                this.setState({ app: { ...this.state.app, isLoading: false } }, () => {
                    callback();
                });
            }
        });
    };



    pushHistoryState = (url) => {
        if (!url) {
            url = `/`;
        }
        console.log('pushHistoryState', url);
        window.history.pushState(this.state.app, null, url);
    }



    getFirstDayIndex = (dateObj, view) => {
        if (view === undefined) {
            view = 'month';
        }

        let actualDays = this.actualDays(dateObj);
        let firstDayToShow = dateObj.date;

        if (view === 'month') {
            firstDayToShow = this.findMonday(new Date(dateObj.year, dateObj.month - 1, 1));
        }

        if (view === '2weeks' || view === 'week') {
            firstDayToShow = this.findMonday(firstDayToShow);
        }

        for (var i = 0; i < actualDays.length; i++) {
            if (actualDays[i].date.getTime() === firstDayToShow.getTime()) {
                return i;
            }
        }
    };

    changeNavCalDate = (date, saveToHistory) => {
        this.setState({ app: { ...this.state.app, navDate: date } }, () => {
            if (saveToHistory) {
                this.pushHistoryState();
            }
        });
    }

    changeCalDate = (date, saveToHistory) => {
        this.setState({ app: { ...this.state.app, date: date } }, () => {
            if (saveToHistory) {
                this.pushHistoryState();
            }
        });
    }

    getHolidays = (dateObj) => {
        let year = dateObj.year;
        return [
            year + '-01-01',
            year + '-01-06',
            year + '-05-01',
            year + '-05-08',
            year + '-07-05',
            year + '-08-29',
            year + '-09-01',
            year + '-09-15',
            year + '-10-30',
            year + '-11-01',
            year + '-11-17',
            year + '-12-24',
            year + '-12-25',
            year + '-12-26',

            '2023-04-07',
            '2023-04-09',
            '2023-04-10',

            '2024-03-29',
            '2024-03-31',
            '2024-04-01',

            '2025-04-18',
            '2025-04-20',
            '2025-04-21',
        ];
    };


    toShow = (dateObj, view) => {
        let fistDayInFirstWeek = new Date(dateObj.year, dateObj.month - 1, 1).getDay();
        if (fistDayInFirstWeek === 0) fistDayInFirstWeek = 7;

        let daysInMonth = new Date(dateObj.year, dateObj.month, 0).getDate();

        switch (view) {
            case '2weeks':
                return {
                    days: 14,
                    weeks: 2,
                    row: 7
                }

            case 'week':
                return {
                    days: 7,
                    weeks: 1,
                    row: 7
                }

            case '3days':
                return {
                    days: 3,
                    weeks: 1,
                    row: 3
                }

            case '1day':
                return {
                    days: 1,
                    weeks: 1,
                    row: 1
                }

            default:
                let daysBefore = fistDayInFirstWeek - 1;
                let daysToShow = daysBefore + daysInMonth;

                // round to nearest higher multiple of 7
                if (daysToShow % 7 !== 0)
                    daysToShow = daysToShow + (7 - (daysToShow % 7));

                return {
                    days: daysToShow,
                    weeks: daysToShow / 7,
                    row: 7
                }
        }
    };

    getReservationStatusStrings = () => {
        return [
            { value: 'ordered', label: 'Objednaná' },
            { value: 'onwater', label: 'Na vode' },
            { value: 'canceled', label: 'Zrušená' },
            { value: 'finished', label: 'Ukončená' },
            { value: 'createinvoice', label: 'Vytvoriť faktúru' },
            { value: 'waitingforpayment', label: 'Čaká na platbu' }
        ];
    }


    formatDate = (date) => {
        return `${(date.getFullYear())}-${this.pad(((date.getMonth()) + 1), 2)}-${this.pad((date.getDate()), 2)}`;
    }


    toggleAddNewReservation = (date = null) => {
        this.setState({ app: { ...this.state.app, addReservation: true, addReservationDate: date } }, () => {
            this.pushHistoryState(`/newreservation`);
        })
    }

    setCurrentlyEditing = (isEditing) => {
        this.setState({ app: { ...this.state.app, isCurrentlyEditing: isEditing } }, () => {
            this.pushHistoryState(`/reservation/${this.state.app.reservation.id}/edit`);
        })
    }


    render() {
        let providerValues = {
            app: {
                state: this.state.app,
                functions: {
                    setShownComponent: this.setShownComponent,
                    updateMainCalendar: this.updateMainCalendar,
                    updateCurrReservation: this.updateCurrReservation,
                    setViewType: this.setViewType,
                    pushHistoryState: this.pushHistoryState,
                    getFirstDayIndex: this.getFirstDayIndex,
                    getDateObj: this.getDateObj,
                    actualDays: this.actualDays,
                    changeNavCalDate: this.changeNavCalDate,
                    changeCalDate: this.changeCalDate,
                    getHolidays: this.getHolidays,
                    toShow: this.toShow,
                    getDisplayText: this.getDisplayText,
                    formatDate: this.formatDate,
                    toggleAddNewReservation: this.toggleAddNewReservation,
                    setCurrentlyEditing: this.setCurrentlyEditing,
                    getPaymentTypes: this.getPaymentTypes,
                    getReservationStatusStrings: this.getReservationStatusStrings,
                    setCurrentReservationTab: this.setCurrentReservationTab,
                    handlePrint: this.props.handlePrint
                },
            },
            qrscanner: {
                state: this.state.qrScanner,
                setResult: this.setQRScannerResult,
                init: this.initQRScanner,
                cancel: this.cancelQRScanner
            },
            socketIO: {
                socket,
                ...this.state.socketIO

            },
        };

        return (
            <AppContext.Provider value={providerValues}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
};

const withAppContext = (Component) => {
    return (props) => {
        return (
            <AppContext.Consumer>
                {(context) => <Component {...props} {...context} />}
            </AppContext.Consumer>
        );
    };
};

const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppProvider');
    }
    return context;
};

export { AppContext, AppProvider, withAppContext, useAppContext };
