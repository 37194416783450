import React from "react";
import { Paper, Typography, Grid } from "@mui/material"


class ClientData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let client = this.props.client;
        return (

            <Paper elevation={3} sx={{ p: 1 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>Klient</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="p" sx={{ mb: 1 }}>Meno</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11}>
                        <Typography variant="p" sx={{ mb: 1 }}>{client.name}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="p" sx={{ mb: 1 }}>Telefón</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11}>
                        <Typography variant="p" sx={{ mb: 1 }}>{client.phone}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="p" sx={{ mb: 1 }}>E-mail</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11}>
                        <Typography variant="p" sx={{ mb: 1 }}>{client.email}</Typography>
                    </Grid>

                </Grid>
            </Paper>
        );
    }
}

export default ClientData;
