import React from "react";
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { withAllContexts } from "../AllContextsWrapper";
import FadeInOut from "./FadeInOut";


//create drawer component which has overlay and content, at the top of drawer shoule be handle, where user can pull it up or down and it should snap to some position (40%, 60%, 80%, 100%) of view height and if it is pulled down to 20% and lower it should close the drawer
//drawer should have some props like:
// isOpen - boolean
// onOpen - function
// onClose - function
// onSnap - function
// snapPositions - array of numbers (0-100) - default [40, 60, 80, 100]
// snapPosition - number (0-100) - default 100

//drawer should have some states like:
// isOpen - boolean
// isDragging - boolean
// isSnapping - boolean
// snapPosition - number (0-100) - default 100
// snapPositions - array of numbers (0-100) - default [40, 60, 80, 100]
// snapPositionIndex - number (0-100) - default 100

//drawer should have some methods like:
// open - function
// close - function
// snapTo - function

//drawer should have some events like:
// onOpen - function
// onClose - function
// onSnap - function

class DrawerComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen,
            isDragging: false,
            snapPosition: 0,
            snapPositions: this.props.snapPositions,
        };
    }

    open = () => {
        this.setState({
            isOpen: true,
        }, () => {
            this.props.onOpen();
        });
    };

    close = () => {
        this.setState({
            isOpen: false,
            snapPosition: 0,
        }, () => {
            this.props.onClose();
        });
    };

    snapTo = (position) => {
        this.setState({
            snapPosition: position,
        }, () => {
            this.props.onSnap(position);
        });
    };

    handleDragStart = (event) => {
        let offset = event.type === "touchmove" ? this.props.touchOffset : this.props.mouseOffset;
        let position = event.clientY || event.targetTouches[0].pageY;
        if (position === 0) return;
        let drawerHeight = ((position + offset) / window.innerHeight) * 100;
        drawerHeight = 100 - drawerHeight;

        this.setState({
            isDragging: true,
            snapPosition: drawerHeight
        });
    };

    handleDragEnd = (event) => {
        event.preventDefault();

        this.setState({
            isDragging: false
        }, () => {
            this.handleSnap();
        });
    };

    handleClick = (event) => {
        event.preventDefault();

        let biggest = 0;
        for (let i = 0; i < this.state.snapPositions.length; i++) {
            if (this.state.snapPositions[i] > biggest) {
                biggest = this.state.snapPositions[i];
            }
        }

        if (event.detail === 2) {
            this.snapTo(biggest);
        }
    };

    handleSnap = () => {
        let lowest = 100;
        for (let i = 0; i < this.state.snapPositions.length; i++) {
            if (this.state.snapPositions[i] < lowest) {
                lowest = this.state.snapPositions[i];
            }
        }

        let closest = 100;
        let closestIndex = 0;
        for (let i = 0; i < this.state.snapPositions.length; i++) {
            if (Math.abs(this.state.snapPosition - this.state.snapPositions[i]) < closest) {
                closest = Math.abs(this.state.snapPosition - this.state.snapPositions[i]);
                closestIndex = i;
            }
        }

        if (this.state.snapPosition < (lowest / 2)) {
            console.log('snap lower than half of lowest snap position, close drawer');
            this.close();
        } else {
            console.log('snap to closest', this.state.snapPositions[closestIndex]);
            this.setState({
                snapPosition: this.state.snapPositions[closestIndex],
            })
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({
                isOpen: this.props.isOpen,
            });
        }

        if (prevProps.snapPosition !== this.props.snapPosition) {
            this.setState({
                snapPosition: this.props.snapPosition,
            });
        }
    }



    handleAfterEnter = () => {
        this.setState({
            snapPosition: this.props.snapPosition,
        });
    }

    handleAfterExit = () => {
        this.setState({
            snapPosition: this.props.snapPosition,
        });
    }

    render() {
        let drawerWraperClassNames = "drawer_wrapper " + (this.state.isDragging ? "" : "animation-enabled");

        return (
            <FadeInOut
                show={this.state.isOpen}
                duration={300}
                className="drawer_wrapper_fade"
                afterEnter={this.handleAfterEnter}>
                <div className="drawer_wrapper_main">
                    <div className="drawer_overlay" onClick={this.close}></div>
                    <div className={drawerWraperClassNames} style={{ height: this.state.snapPosition + 'vh' }}>
                        <div
                            className="drawer_handle_wrapper"
                            onDrag={this.handleDragStart}
                            onDragEnd={this.handleDragEnd}
                            onTouchMove={this.handleDragStart}
                            onTouchEnd={this.handleDragEnd}
                            onClick={this.handleClick}>
                            <div className="drawer_handle"></div>
                        </div>
                        <div className="drawer_content">{this.props.children}</div>
                    </div>
                </div>
            </FadeInOut>
        );
    }
}


class Drawer extends React.Component {
    render() {
        return (
            <>
                {createPortal(<DrawerComponent {...this.props} />, document.body)}
            </>
        );
    }
}

Drawer.propTypes = {
    isOpen: PropTypes.bool,
    snapPositions: PropTypes.arrayOf(PropTypes.number),
    snapPosition: PropTypes.number,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSnap: PropTypes.func,
    touchOffset: PropTypes.number,
    mouseOffset: PropTypes.number,
};

Drawer.defaultProps = {
    isOpen: false,
    onOpen: () => { },
    onClose: () => { },
    onSnap: () => { },
    snapPositions: [40, 60, 80, 95],
    snapPosition: 90,
    touchOffset: -40,
    mouseOffset: -40,
};

export default withAllContexts(Drawer);
