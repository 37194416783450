import React from 'react'
import { withAllContexts } from '../../../AllContextsWrapper';
import { Paper, Button, Grid, TextField, Autocomplete, MenuItem } from "@mui/material";
import {  enqueueSnackbar } from 'notistack';

class AddFee extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      form: {
        added_by: this.props.socketIO.user.id,
        reservation: this.props.reservation.id,
        category: "transport",
        description: "",
        price: 0,
        amount: 1,
      }
    };
  }

  getData = (category) => {
    console.log('get data', category)
    if (category === "transport") {

      this.props.socketIO.socket.emit('places:getAll', (response) => {
        if (response.success === true) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].description = response.data[i]['name'];
            delete response.data[i].name;
          }
          let a = response.data.map((d) => {
            return {
              label: d.description,
              id: d.id,
              price: d.price
            }
          });
          console.log(a)
          this.setState({
            data: a
          })
        } else {
          this.setState({
            data: []
          })
        }
      });
    } else {
      this.props.socketIO.socket.emit('feetemplate:getAll', (response) => {
        console.log('feetemplate', response)
        if (response.success === true) {
          let a = response.data.map((d) => {
            return {
              label: d.description,
              id: d.id,
              price: d.price
            }
          });
          this.setState({
            data: a
          })
        } else {
          this.setState({
            data: []
          })
        }
      });
    }
  }

  componentDidMount() {
    if (this.props.reservation) {
      this.getData(this.state.form.category);
    }
  }

  handleCategory = (value) => {
    this.setState({
      form: {
        added_by: this.props.socketIO.user.id,
        reservation: this.props.reservation.id,
        category: value,
        description: "",
        price: 0,
        amount: 1
      }
    }, () => this.getData(value))
  }

  handleDescriptionChange = (description, price) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        description: description,
        price: price
      }
    }), () => {
      if(price) {
        this.getPrice(this.state.form.description)
      }
    })
  }

  updateFormState = (value, name) => {
    this.setState(prevState => ({
      form: {                   // object that we want to update
        ...prevState.form,    // keep all other key-value pairs
        [name]: value       // update the value of specific key
      }
    }))
  }

  getPrice = (description) => {
    let data = this.state.data;
    for (let i = 0; i < data.length; i++) {
      if (data[i].description === description) {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            price: data[i].price
          }
        }))
      }
    }
  }
  handleFormSubmit = async (event) => {
    event.preventDefault();

    this.props.socketIO.socket.emit('fee:create', this.state.form, (response) => {
      if (response.success === true) {
        this.props.app.functions.updateCurrReservation(this.props.reservation.id);
        enqueueSnackbar("Poplatok úspešne pridaný", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Poplatok sa nepodarilo pridať", {
          variant: "error",
        });
      }
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          reservation: this.props.reservation.id,
          category: "transport",
          description: "",
          price: 0,
          amount: 1,
        }
      }))
    });
  }

  render() {
    /* let data = this.state.data;
    if (!data) {
      data.push({
        label: "Nenašli sa žiadne dáta",
        id: 0,
      })
    } */

    return (
      <Paper elevation={3} sx={{ p: 2}}>
        <form onSubmit={(e) => this.handleFormSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                select
                label="Kategória"
                value={this.state.form.category}
                onChange={(e) => this.handleCategory(e.target.value)}
              >
                <MenuItem value="transport">Prevoz</MenuItem >
                <MenuItem value="camping">Táborisko</MenuItem >
                <MenuItem value="other">Iné</MenuItem >
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                disablePortal
                options={this.state.data}
                renderInput={(params) => <TextField {...params} label="Popis" />}
                onInputChange={(event, value, reason) => {
                  if (reason === 'input') {
                    this.handleDescriptionChange(value);
                    //this.updateFormState(value, 'end_place');
                  }
                }}
                onChange={(event, option, reason) => {
                  if (reason === 'selectOption') {
                    this.handleDescriptionChange(option.label, option.price);
                    //this.updateFormState(option.label, 'end_place');
                  }
                }
                }
                freeSolo
                value={this.state.form.description}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Cena"
                value={this.state.form.price}
                onChange={(e) => this.updateFormState(e.target.value, "price")}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Množstvo"
                value={this.state.form.amount}
                onChange={(e) => this.updateFormState(e.target.value, "amount")}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Pridať
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

export default withAllContexts(AddFee);
