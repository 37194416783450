import React from 'react'
import AddPayment from './AddPayment';
import { Icon } from '../../Icons'
import { confirmAlert } from 'react-confirm-alert';
import { withAllContexts } from '../../../AllContextsWrapper';
import { enqueueSnackbar } from 'notistack';
import { Box, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material';

class Payments extends React.Component {

  deletePaymentConfirmation = (paymentId) => {
    confirmAlert({
      title: 'Zmazanie platby',
      message: 'Naozaj chceš vymazať túto platbu?',
      buttons: [
        {
          label: 'Zrušiť'
        },
        {
          label: 'Zmazať',
          onClick: () => this.deletePayment(paymentId)
        }
      ]
    });
  }

  deletePayment = (paymentId) => {

    this.props.socketIO.socket.emit('payment:delete', paymentId, (response) => {
      if (response.success === true) {
        this.props.app.functions.updateCurrReservation(this.props.reservation.id);
        enqueueSnackbar("Platba úspešne odstránená", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Platbu sa nepodarilo odstrániť", {
          variant: "error",
        });
      }
    });
  }

  render() {
    let reservation = this.props.reservation
    let payments = reservation.payments;

    if (payments.length) {
      return (
        <Box sx={{ width: '100%' }}>
          <TableContainer component={Paper} elevation={3} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Prijal</TableCell>
                  <TableCell>Typ</TableCell>
                  <TableCell>Suma</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment, key) => {
                  let type = payment.type ? this.props.app.functions.getPaymentTypes()[payment.type] : "----";
                  return (
                    <TableRow
                      key={key + "_payment"}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{this.props.socketIO.usersShort[payment.received_by]}</TableCell>
                      <TableCell>{type}</TableCell>
                      <TableCell>{payment.amount} €</TableCell>
                      <TableCell>
                        <IconButton aria-label="delete" onClick={(e) => this.deletePaymentConfirmation(payment.id)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <AddPayment
            reservation={this.props.reservation}
          />
        </Box>
      )
    } else {
      return (
        <Box sx={{ width: '100%' }}>
          <Paper elevation={3} sx={{ mb: 2 }}>
            <Typography variant="h6" component="div" sx={{ p: 2 }}>
              <Icon icon="payment" sx={{ mr: 1 }} />
              Zatiaľ žiadne platby
            </Typography>
          </Paper>
          <AddPayment
            reservation={this.props.reservation}
          />
        </Box>
      )
    }
  }
}

export default withAllContexts(Payments);
