import * as React from 'react'
import { withAllContexts } from '../../../AllContextsWrapper';
import { Box, Grid, TextField, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';



class Equipment extends React.PureComponent {
  constructor(props) {
    super(props);
    let { color, client, fees, payments, ...form } = this.props.reservation;
    this.state = {
      isLoading: false,
      form: {
        ...form
      }
    };
  }

  updateFormState = (value, name) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }))
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();

    this.props.socketIO.socket.emit('reservations:update', this.state.form, (response) => {
      if (response.success === true) {
        this.props.app.functions.updateMainCalendar();
        this.props.app.functions.updateCurrReservation(this.state.form.id);
        enqueueSnackbar("Výbava úspešne aktualizovaná", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Výbavu sa nepodarilo aktualizovať", {
          variant: "error",
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.reservation !== prevProps.reservation) {
      let { color, client, fees, payments, ...form } = this.props.reservation;
      this.setState(({
        form: {
          ...form
        }
      }))
    }
  }

  render() {
    return (
      <Box>
        <form onSubmit={(e) => this.handleFormSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>

              <TextField
                label="Kanoe"
                type="number"
                fullWidth
                name="kanoe_count"
                value={this.state.form.kanoe_count}
                onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Katamarány"
                type="number"
                fullWidth
                name="katamaran_count"
                value={this.state.form.katamaran_count}
                onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pádla"
                type="number"
                fullWidth
                name="paddle_count"
                value={this.state.form.paddle_count}
                onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Vesty"
                type="number"
                fullWidth
                name="vest_count"
                value={this.state.form.vest_count}
                onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Barely"
                type="number"
                fullWidth
                name="barrel_count"
                value={this.state.form.barrel_count}
                onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Poznámka"
                type="text"
                multiline
                rows={4}
                fullWidth
                name="equipment_note"
                value={this.state.form.equipment_note === null ? undefined : this.state.form.equipment_note}
                onChange={(e) => this.updateFormState(e.target.value, e.target.name)}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                variant="outlined"
                onClick={() => this.props.app.functions.handlePrint()} fullWidth
              >
                Tlačiť
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                variant="contained"
                onClick={(e) => this.handleFormSubmit(e)} fullWidth
              >
                Uložiť
              </Button>
            </Grid>
          </Grid>

        </form>
      </Box>


    );
  }
}
export default withAllContexts(Equipment);
