import React from 'react';
import Payments from './Reservation/Payments';
import Fees from './Reservation/Fees';
import Equipment from './Reservation/Equipment';
import Overview from './Reservation/Overview';
import History from './Reservation/History';
import Messages from './Reservation/Messages';
import { withAllContexts } from '../../AllContextsWrapper';
import { confirmAlert } from './ConfirmationDialog';
import ClientData from './Client/Data';
import ReservationData from './Reservation/Data';
import { enqueueSnackbar } from 'notistack';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { AppBar, Dialog, Typography, Toolbar, IconButton, Slide, Box, BottomNavigation, BottomNavigationAction, Menu, MenuItem, Divider, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';
import {
  Close as CloseIcon,
  Info as InfoIcon,
  FormatListBulleted as FormatListBulletedIcon,
  AddShoppingCart as AddShoppingCartIcon,
  Euro as EuroIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  History as HistoryIcon,
  Message as MessagesIcon
} from '@mui/icons-material';



class ReservationModal extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      menuAnchorEl: null,
      reservationMenuOpen: false,
    };
  }

  deleteReservationConfirmation = (reservationId) => {
    confirmAlert({
      title: 'Zmazanie rezervácie',
      message: 'Naozaj chceš vymazať túto rezerváciu?',
      buttons: [
        {
          label: 'Zrušiť'
        },
        {
          label: 'Zmazať',
          onClick: () => this.deleteReservation(reservationId)
        }
      ]
    });
  }

  deleteReservation = (reservationId) => {

    this.props.socketIO.socket.emit('reservations:delete', reservationId, (response) => {
      if (response.success === true) {
        this.props.app.functions.updateMainCalendar();
        enqueueSnackbar("Rezervácia úspešne odstránená", {
          variant: "success",
        });
        window.history.back();
      } else {
        console.log('cannot delete reservation', response);
        enqueueSnackbar("Rezerváciu sa nepodarilo odstrániť", {
          variant: "error",
        });
      }
    });
  }


  closeModal = () => {
    window.history.back();
  }


  datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  switchCard = (card) => {
    this.props.app.functions.setCurrentReservationTab(card);
  }

  toggleEdit = () => {
    this.props.app.functions.setCurrentlyEditing(!this.props.app.state.isCurrentlyEditing);
  }

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null, reservationMenuOpen: false })
  }

  handlePullToRefresh = () => {
    return new Promise((resolve) => {

      this.props.app.functions.updateCurrReservation(this.props.app.state.reservation.id, false, () => {
        resolve();
      })
    });
  }

  render() {
    let reservation = this.props.app.state.reservation;
    let isSetReservation = false;
    let startDate;
    let endDate;
    let reservationLength;
    let dateText;
    let appBarTitle = "Rezervácia #";

    if (reservation !== null) {
      isSetReservation = true;
      startDate = new Date(reservation.start_date);
      endDate = new Date(reservation.end_date);
      reservationLength = this.datediff(startDate, endDate) + 1;
      dateText = startDate.toLocaleDateString().replace(/\s/g, "");


      if (startDate.getTime() !== endDate.getTime()) {
        dateText = dateText + " - " + endDate.toLocaleDateString().replace(/\s/g, "")
      }
      appBarTitle = appBarTitle + reservation.id;
    }

    if (this.props.app.state.currentReservationTab === 0) appBarTitle = appBarTitle + " - Prehľad";
    if (this.props.app.state.currentReservationTab === 1) appBarTitle = appBarTitle + " - História";
    if (this.props.app.state.currentReservationTab === 2) appBarTitle = appBarTitle + " - Výbava";
    if (this.props.app.state.currentReservationTab === 3) appBarTitle = appBarTitle + " - Poplatky";
    if (this.props.app.state.currentReservationTab === 4) appBarTitle = appBarTitle + " - Platby";
    if (this.props.app.state.currentReservationTab === 5) appBarTitle = appBarTitle + " - Správy";

    return (

      <Dialog
        fullScreen
        open={isSetReservation}
        //onClose={() => this.toggleAdding()}
        TransitionComponent={Transition}
      >
        {isSetReservation &&
          <Box sx={{ pb: 7, pt: 7 }}>

            <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10 }}>
              <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => this.closeModal()}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {appBarTitle}
                  </Typography>
                  <IconButton
                    edge="end"
                    color="inherit"
                    id="reservation-menu-button"
                    onClick={(e) => this.setState({ menuAnchorEl: e.currentTarget, reservationMenuOpen: true })}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    id="reservation-menu"
                    MenuListProps={{
                      'aria-labelledby': 'reservation-menu-button',
                    }}
                    anchorEl={this.state.menuAnchorEl}
                    open={this.state.reservationMenuOpen}
                    onClose={this.handleMenuClose}
                  >
                    <MenuItem onClick={(e) => this.toggleEdit()} disableRipple>
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Upraviť</ListItemText>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={(e) => this.switchCard(5)} disableRipple>
                      <ListItemIcon>
                        <MessagesIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Správy</ListItemText>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={(e) => this.deleteReservationConfirmation(reservation.id)} disableRipple>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Vymazať</ListItemText>
                    </MenuItem>
                  </Menu>
                </Toolbar>
              </AppBar>
            </Box>

            <PullToRefresh
              onRefresh={this.handlePullToRefresh}
              pullDownThreshold={70}
              maxPullDownDistance={80}
              resistance={3}
              pullingContent=""
              refreshingContent={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <CircularProgress size={40} sx={{ color: 'primary.main', mt: 2, mb: 2 }} />
                </Box>
              }
            >
              <Box sx={{ p: 2 }}>
                {this.props.app.state.currentReservationTab === 0 &&
                  <>
                    <ClientData client={reservation.client} />
                    <ReservationData
                      reservation={reservation}
                      reservationLength={reservationLength}
                      dateText={dateText}
                    />
                    <Overview
                      reservation={reservation}
                      reservationLength={reservationLength}
                    />
                  </>
                }

                {this.props.app.state.currentReservationTab === 1 &&
                  <>
                    <History
                      reservation={reservation}
                    />
                  </>
                }

                {this.props.app.state.currentReservationTab === 2 &&
                  <>
                    <Equipment
                      reservation={reservation}
                    />
                  </>
                }

                {this.props.app.state.currentReservationTab === 3 &&
                  <>
                    <Fees
                      reservation={reservation}
                    />
                  </>
                }

                {this.props.app.state.currentReservationTab === 4 &&
                  <>
                    <Payments
                      reservation={reservation}
                    />
                  </>
                }

                {this.props.app.state.currentReservationTab === 5 &&
                  <>
                    <Messages
                      reservation={reservation}
                    />
                  </>
                }

              </Box>
            </PullToRefresh>
            <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>

              <BottomNavigation
                showLabels
                value={this.props.app.state.currentReservationTab}
                onChange={(event, newValue) => {
                  this.switchCard(newValue);
                }}
                sx={{
                  // selected and (selected + hover) states
                  '&& .Mui-selected, && .Mui-selected:hover': {
                    '&, & .MuiListItemIcon-root': {
                      color: 'secondary.main',
                    },
                  },
                  // hover states
                  '& .MuiListItemButton-root:hover': {
                    '&, & .MuiListItemIcon-root': {
                      color: 'secondary.main',
                    },
                  },
                }}>
                <BottomNavigationAction label="Prehľad" icon={<InfoIcon />} />
                <BottomNavigationAction label="História" icon={<HistoryIcon />} />
                {/* <BottomNavigationAction label="Správy" icon={<MessagesIcon />} /> */}
                <BottomNavigationAction label="Výbava" icon={<FormatListBulletedIcon />} />
                <BottomNavigationAction label="Poplatky" icon={<AddShoppingCartIcon />} />
                <BottomNavigationAction label="Platby" icon={<EuroIcon />} />
              </BottomNavigation>
            </Box>
          </Box>
        }
      </Dialog>


    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default withAllContexts(ReservationModal);
