import * as React from 'react'
import { withAllContexts } from '../../../AllContextsWrapper';
import { diff } from 'deep-object-diff';
import { Box, Typography, Grid, Backdrop, CircularProgress } from '@mui/material';
import { TimelineOppositeContent, Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, timelineOppositeContentClasses } from '@mui/lab';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

class HistoryPart extends React.Component {

    render() {
        let valuesChanged = this.props.valuesChanged;

        return (
            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="text.primary">
                        {this.props.date}
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary">
                        {this.props.author}
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Box sx={{ p: 2 }}>
                        {valuesChanged.map((v, key) => {
                            v.value = v.value.toString().length > 0 ? v.value : '-----';
                            let value = v.newValue.toString().length > 0 ? (
                                <Grid container direction="row">
                                    <Grid item>
                                        <Typography variant="body2" color="error">
                                            {this.props.app.functions.getDisplayText(v.value)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <ArrowForwardIcon fontSize="small" color="info" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" color="success.main">
                                            {this.props.app.functions.getDisplayText(v.newValue)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Typography variant="body2">
                                    {v.value}
                                </Typography>
                            );
                            return (
                                <Grid container key={'history_part_value' + key}>
                                    <Grid xs={12} item>
                                        <Typography variant="body2">
                                            {this.props.app.functions.getDisplayText(v.name)}:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} item>
                                        {value}
                                    </Grid>
                                </Grid>
                            )
                        })
                        }
                    </Box>
                </TimelineContent>
            </TimelineItem>
        );
    }
}




class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }


    formatDate = (date) => {
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = '' + d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day

        let dateStr = [year, month, day].join('-');
        return dateStr;
    }

    formatDateTime = (date) => {
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = '' + d.getFullYear();
        let hours = '' + d.getHours();
        let minutes = '' + d.getMinutes();


        let dateStr = [day, month, year].join('.');
        let timeStr = [hours, minutes].join(':');
        return dateStr + " " + timeStr;
    }

    getHistory = async () => {
        this.setState({ isLoading: true });
        this.props.socketIO.socket.emit('reservationHistory:getAllByReservationId', this.props.reservation.id, (response) => {

            if (response.success === true) {
                this.setState({
                    history: response.data,
                    isLoading: false
                })
            } else {
                this.setState({
                    history: [],
                    isLoading: false
                })
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.reservation !== prevProps.reservation) {
            /* let { color, client, fees, payments, ...form } = this.props.reservation;
            console.log('equipment form', form);
            this.setState(prevState => ({
                form: {
                    ...form
                }
            })) */
        }
    }

    componentDidMount() {
        if (this.props.reservation) {
            this.getHistory();
        }
    }

    render() {
        let reservation = this.props.reservation;
        let content = [];

        if (this.state.history) {
            let { id, client, payments, fees, updatedAt, ...currentValues } = this.props.reservation;
            let keysCount = Object.keys(this.state.history).length;
            this.state.history.forEach((historyPartFull, keyhistoryPart) => {
                let valuesChanged = [];
                let { archivedAt, id, updatedAt, modelId, edited_by, client, ...historyPart } = historyPartFull;
                let diffObj = diff(currentValues, historyPart);
                for (let key in diffObj) {
                    if (key === 'start_date' || key === 'end_date') {
                        valuesChanged.push({ name: key, value: this.formatDate(diffObj[key]), newValue: this.formatDate(currentValues[key]) });
                    } else {
                        valuesChanged.push({ name: key, value: diffObj[key], newValue: currentValues[key] });
                    }
                }
                content.push(
                    <HistoryPart
                        {...this.props}
                        key={"history_part" + keyhistoryPart}
                        number={keysCount + 1}
                        date={this.formatDateTime(archivedAt)}
                        author={this.props.socketIO.usersShort[edited_by]}
                        valuesChanged={valuesChanged}
                    />
                );
                keysCount--;
                currentValues = historyPart;
            });
        }

        content.push(
            <HistoryPart
                {...this.props}
                key="createdAt"
                number={1}
                date={this.formatDateTime(reservation.createdAt)}
                author={"Systém"}
                valuesChanged={[{ name: "Vytvorená rezervácia", value: reservation.id, newValue: '' }]}
            />
        );


        return (

            <>
                <Backdrop
                    sx={{ color: '#fff' }}
                    open={this.state.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box>
                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                            },
                        }}>
                        {content}
                    </Timeline>
                </Box>
            </>


        );
    }
}
export default withAllContexts(History);
