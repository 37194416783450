import React from 'react';
import { useAppContext } from '../AppContext';

export const PrintComponent = React.forwardRef((props, ref) => {

    const app = useAppContext();
    let reservation = app.app.state.reservation;

    let content = (<></>);
    if (reservation !== null) {
        let startDate = new Date(reservation.start_date);
        let endDate = new Date(reservation.end_date);
        //let reservationLength = datediff(startDate, endDate) + 1;
        let dateText = startDate.toLocaleDateString().replace(/\s/g, "");

        if (startDate.getTime() !== endDate.getTime()) {
            dateText = dateText + " - " + endDate.toLocaleDateString().replace(/\s/g, "")
        }

        content = (
            <>
                <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>Preberací lístok</h2>
                <p>................................</p>
                <p>ˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇ</p>
                <br />
                <p>Splavma s.r.o.</p>
                <p>Tomášovská 2/16</p>
                <p>900 29 Nová Dedinka</p>
                <p>................................</p>
                <p>ˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇ</p>
                <p><span>Termín splavu: <br /></span><span>{dateText}</span></p>
                <p>................................</p>
                <p>ˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇ</p>
                <p><span>Počet kanoe: </span><span>{reservation.kanoe_count} ({reservation.katamaran_count} katamaránov)</span></p>
                <p><span>Počet pádiel: </span><span>{reservation.paddle_count}</span></p>
                <p><span>Počet viest: </span><span>{reservation.vest_count}</span></p>
                <p><span>Počet barelov: </span><span>{reservation.barrel_count}</span></p>
                <p>................................</p>
                <p>ˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇ</p>
                <p><span>Poznámka: </span><br /><span>{reservation.equipment_note}</span></p>
                <p>................................</p>
                <p>ˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇˇ</p>
                <p>Ahóóóój!</p>

            </>
        )
    }


    return (
        <div ref={ref} >
            {content}
        </div>
    );
});
