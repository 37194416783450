import React from 'react'
import { withAllContexts } from '../../../AllContextsWrapper';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

class Overview extends React.Component {

  render() {
    let reservation = this.props.reservation;
    let feesPrice = 0;
    let paymentsAmount = 0;

    reservation.fees.forEach((fee, i) => {
      feesPrice = feesPrice + (fee.amount * fee.price);
    })

    reservation.payments.forEach((payment, i) => {
      paymentsAmount = paymentsAmount + payment.amount;
    })

    let canoePricesSingleDay = {
      1: 20,
      2: 20,
      3: 20,
      4: 20,
      5: 22,
      6: 22,
      7: 22,
      holiday: 22
    }

    let canoePricesMoreDays = {
      1: 17,
      2: 17,
      3: 17,
      4: 17,
      5: 18,
      6: 18,
      7: 18,
      holiday: 18
    }

    let canoePrice = 0;
    let isInformationOnly = false;
    let kanoeCount = reservation.kanoe_count;
    if (kanoeCount === 0) {
      kanoeCount = reservation.kanoe_count_info_max;
      isInformationOnly = true;
    }

    if (this.props.reservationLength === 1) {
      let date = new Date(reservation.start_date);
      let holidays = this.props.app.functions.getHolidays({ year: date.getFullYear() });
      let dayInWeek = date.getDay();
      dayInWeek = dayInWeek === 0 ? 7 : dayInWeek;
      let formatedDate = this.props.app.functions.formatDate(date);
      if (holidays.includes(formatedDate)) {
        canoePrice = canoePricesSingleDay['holiday'] * kanoeCount;
      } else {
        canoePrice = canoePricesSingleDay[dayInWeek] * kanoeCount;
      }

    } else if (this.props.reservationLength > 1) {
      for (var i = 0; i < this.props.reservationLength; i++) {
        let date = new Date(reservation.start_date);
        date.setDate(date.getDate() + i);
        let dayInWeek = date.getDay() === 0 ? 7 : date.getDay();
        dayInWeek = dayInWeek === 0 ? 7 : dayInWeek;
        let holidays = this.props.app.functions.getHolidays({ year: date.getFullYear() });
        let formatedDate = this.props.app.functions.formatDate(date);

        if (holidays.includes(formatedDate)) {
          canoePrice = canoePrice + canoePricesMoreDays['holiday'] * kanoeCount;
        } else {
          canoePrice = canoePrice + canoePricesMoreDays[dayInWeek] * kanoeCount;
        }
      }
    }


    return (
      <TableContainer component={Paper} sx={{ mt: 1, mb: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Položka</TableCell>
              <TableCell align="right">Cena</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">Poplatky</TableCell>
              <TableCell align="right">{feesPrice}&nbsp;€</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">Kanoe</TableCell>
              <TableCell align="right">{isInformationOnly ? 'ℹ ' : ''}{canoePrice}&nbsp;€</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">Zaplatené</TableCell>
              <TableCell align="right">{paymentsAmount}&nbsp;€</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">Doplatiť</TableCell>
              <TableCell align="right">{feesPrice + canoePrice - paymentsAmount}&nbsp;€</TableCell>
            </TableRow>


          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withAllContexts(Overview);
