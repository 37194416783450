import React, { isValidElement } from "react";
import { Alert } from "@mui/material";
import { closeSnackbar } from "notistack";

const InfoAlert = React.forwardRef((props, ref) => {

    let alertProps = {
        ref: ref,
        severity: "info",
        sx: { width: "100%" }
    }

    if (isValidElement(props.action)) {
        alertProps.action = props.action;
    } else {
        alertProps.onClose = () => {
            closeSnackbar(props.id);
        }
    }


    return (
        <Alert
            {...alertProps}
        >
            {props.message}
        </Alert>
    );
});

export default InfoAlert;
