import React from 'react'
import { withAllContexts } from '../../../AllContextsWrapper';
import { Box, Paper, Button, Grid, TextField, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Select, MenuItem, Typography, Backdrop, CircularProgress } from "@mui/material";
import { CameraAlt as CameraIcon } from "@mui/icons-material";
import { enqueueSnackbar } from 'notistack';

class AddPayment extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      form: {
        received_by: this.props.socketIO.user.id,
        reservation: this.props.reservation.id,
        type: '',
        giftcard_number: '',
        amount: '',
      },
      loading: false,
      giftCard: null,
      giftCardChecked: false,
    };
  }

  updateFormState = (value, name) => {
    this.setState(prevState => ({
      form: {                   // object that we want to update
        ...prevState.form,    // keep all other key-value pairs
        [name]: value       // update the value of specific key
      }
    }))
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();


    if (this.state.form.type === 'giftcard') {
      if (this.state.giftCardChecked === false) {
        enqueueSnackbar("Poukaz nie je overený", {
          variant: "error",
        });
        return;
      }

      if (+this.state.giftCard.balance < this.state.form.amount) {
        enqueueSnackbar("Nedá sa uplatniť väčšia čiastka ako má poukaz k dispozícií", {
          variant: "error",
        });
        return;
      }

      this.props.socketIO.socket.emit('giftcard:redeem', { giftcard_id: this.state.giftCard.voucher_number, redeem_amount: this.state.form.amount }, (response) => {
        console.log('giftcard:redeem', this.state.giftCard, response);

        if (response.success === true) {
          if (response.data.success === true) {
            enqueueSnackbar("Poukaz úspešne uplatnený", {
              variant: "success",
            });
            this.handleAddPayment();
          } else {
            enqueueSnackbar("Poukaz sa nepodarilo uplatniť", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Poukaz sa nepodarilo uplatniť", {
            variant: "error",
          });
        }
      });
    } else {
      this.handleAddPayment();
    }
  }

  handleAddPayment = () => {

    this.props.socketIO.socket.emit('payment:create', this.state.form, (response) => {
      if (response.success === true) {
        this.props.app.functions.updateCurrReservation(this.props.reservation.id);
        enqueueSnackbar("Platba úspešne pridaná", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Platbu sa nepodarilo pridať", {
          variant: "error",
        });
      }
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          reservation: this.props.reservation.id,
          type: '',
          amount: '',
        },
        loading: false,
        giftCard: null,
        giftCardChecked: false,
      }))
    });
  }

  handleQRCodeResult = (result) => {
    this.updateFormState(result, 'giftcard_number');
    this.handleCheckBalance();
  }

  handleCheckBalance = () => {
    let giftcard_number = this.state.form.giftcard_number;
    this.setState({ loading: true, giftCardChecked: false });
    this.props.socketIO.socket.emit('giftcard:checkBalance', giftcard_number, (response) => {
      if (response.success === true) {

        if (response.data.success === true) {
          if (response.data.state === 'used') {
            enqueueSnackbar("Poukaz je už použitý", {
              variant: "error",
            });
          } else if (response.data.expired) {
            enqueueSnackbar("Poukaz je expirovaný", {
              variant: "error",
            });
          } else if (response.data.paid === false) {
            enqueueSnackbar("Poukaz nie je zaplatený", {
              variant: "error",
            });
          } else if (+response.data.balance === 0) {
            enqueueSnackbar("Poukaz je vyčerpaný", {
              variant: "error",
            });
          } else {
            this.setState({ giftCard: response.data, giftCardChecked: true });
            this.updateFormState(response.data.balance, 'amount');
            enqueueSnackbar("Poukaz je platný", {
              variant: "success",
            });
          }

        } else {
          enqueueSnackbar("Poukaz neexistuje", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Poukaz sa nepodarilo načítať", {
          variant: "error",
        });
      }
      this.setState({ loading: false });
    });
  }


  render() {
    let paymentTypes = this.props.app.functions.getPaymentTypes();
    let disabled = this.state.form.type === 'giftcard' || this.state.form.type === '' ? (this.state.giftCardChecked ? false : true) : false;
    //console.log((this.state.form.type === 'giftcard' && this.state.giftCardChecked) || this.state.form.type !== '')

    return (
      <Box>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Backdrop
            sx={{ color: '#fff' }}
            open={this.state.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <form onSubmit={(e) => this.handleFormSubmit(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="payment-type-label">Typ platby</InputLabel>
                  <Select
                    labelId="payment-type-label"
                    id="payment-type"
                    value={this.state.form.type}
                    label="Typ platby"
                    onChange={(e) => this.updateFormState(e.target.value, 'type')}
                  >
                    {Object.keys(paymentTypes).map((key, index) => {
                      return <MenuItem key={index} value={key}>{paymentTypes[key]}</MenuItem>
                    })
                    }
                  </Select>
                </FormControl>
              </Grid>
              {this.state.form.type === 'giftcard' &&
                <>
                  <Grid item xs={9}>

                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="giftcard-number">Číslo poukazu</InputLabel>
                      <OutlinedInput
                        id="giftcard-number"
                        label="Číslo poukazu"
                        variant="outlined"
                        value={this.state.form.giftcard_number}
                        onChange={(e) => this.updateFormState(e.target.value, 'giftcard_number')}
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => { this.props.qrscanner.init(this.handleQRCodeResult) }}
                              onMouseDown={() => { this.props.qrscanner.init(this.handleQRCodeResult) }}
                              edge="end"
                            >
                              <CameraIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      sx={{ height: '56px' }}
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleCheckBalance()}
                      fullWidth
                    >
                      Overiť
                    </Button>
                  </Grid>
                </>
              }

              {this.state.giftCard &&
                <>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                      Zostatok: {+this.state.giftCard.balance} €
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                      Platný do: {this.state.giftCard.expiry_date}
                    </Typography>
                  </Grid>
                </>

              }

              {((this.state.form.type === 'giftcard' && !this.state.giftCardChecked) || this.state.form.type !== '') &&
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Suma"
                      variant="outlined"
                      value={+this.state.form.amount}
                      onChange={(e) => this.updateFormState(e.target.value, 'amount')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disabled}
                      fullWidth
                    >
                      Pridať platbu
                    </Button>
                  </Grid>
                </>
              }
            </Grid>
          </form>
        </Paper>
      </Box>
    )
  }
}

export default withAllContexts(AddPayment);
