import React from "react";
import { withAllContexts } from "../../../AllContextsWrapper"
import { Box, Paper, Grid, Typography, Divider } from "@mui/material";

class ReservationData extends React.Component {

    render() {
        let reservation = this.props.reservation;

        return (
            <Box sx={{ mt: 1 }}>
                <Paper elevation={3} sx={{ p: 1 }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>Rezervácia</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={1}>
                            <Typography variant="p" sx={{ mb: 1 }}>Stav</Typography>
                        </Grid>
                        <Grid item xs={8} sm={11}>
                            <Typography variant="p" sx={{ mb: 1 }}>{this.props.app.functions.getDisplayText(reservation.status)}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={1}>
                            <Typography variant="p" sx={{ mb: 1 }}>Dĺžka splavu</Typography>
                        </Grid>
                        <Grid item xs={8} sm={11}>
                            <Typography variant="p" sx={{ mb: 1 }}>{this.props.reservationLength}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={1}>
                            <Typography variant="p" sx={{ mb: 1 }}>Termín</Typography>
                        </Grid>
                        <Grid item xs={8} sm={11}>
                            <Typography variant="p" sx={{ mb: 1 }}>{this.props.dateText}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={1}>
                            <Typography variant="p" sx={{ mb: 1 }}>Počet kanoe</Typography>
                        </Grid>
                        <Grid item xs={8} sm={11}>
                            <Typography variant="p" sx={{ mb: 1 }}>
                                {
                                    reservation.kanoe_count_info_min === reservation.kanoe_count_info_max ?
                                        reservation.kanoe_count_info_min :
                                        `${reservation.kanoe_count_info_min} - ${reservation.kanoe_count_info_max}`
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={1}>
                            <Typography variant="p" sx={{ mb: 1 }}>Počet katamaránov</Typography>
                        </Grid>
                        <Grid item xs={8} sm={11}>
                            <Typography variant="p" sx={{ mb: 1 }}>{reservation.katamaran_count_info}</Typography>
                        </Grid>
                        {reservation.start_place &&
                            <Grid item xs={4} sm={1}>
                                <Typography variant="p" sx={{ mb: 1 }}>Štart</Typography>
                            </Grid>
                        }
                        {reservation.start_place &&
                            <Grid item xs={8} sm={11}>
                                <Typography variant="p" sx={{ mb: 1 }}>{reservation.start_place}</Typography>
                            </Grid>
                        }
                        {reservation.end_place &&
                            <Grid item xs={4} sm={1}>
                                <Typography variant="p" sx={{ mb: 1 }}>Cieľ</Typography>
                            </Grid>
                        }
                        {reservation.end_place &&
                            <Grid item xs={8} sm={11}>
                                <Typography variant="p" sx={{ mb: 1 }}>{reservation.end_place}</Typography>
                            </Grid>
                        }

                        {reservation.note &&
                            <Grid item xs={12} sm={12}>
                                <Divider component="div" role="presentation" />
                            </Grid>
                        }

                        {reservation.note &&
                            <Grid item xs={12} sm={12}>
                                <Typography variant="p" sx={{ mb: 1 }}>Poznámka</Typography>
                            </Grid>
                        }
                        {reservation.note &&
                            <Grid item xs={12} sm={12}>
                                <Typography variant="p" sx={{ mb: 1 }}>{reservation.note}</Typography>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Box>
        );
    }
}

export default withAllContexts(ReservationData);
