import React from 'react'
import { withAllContexts } from '../AllContextsWrapper';
import RangeSelect from './Calendar/RangeSelect';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';

class Header extends React.Component {

  toggleSideMenu = () => {
    if (this.props.app.state.shownComponent === 'sideMenu') {
      this.props.app.functions.setShownComponent(null);
    } else {
      this.props.app.functions.setShownComponent('sideMenu');
    }
  }

  render() {
    return (
      <AppBar
        position="static"
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={this.toggleSideMenu}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Kalendár rezervácii
          </Typography>
          <RangeSelect />
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'block', md: 'none' }, ml: 'auto' }}
            onClick={() => {
              if (this.props.app.state.shownComponent !== 'navCal')
                this.props.app.functions.setShownComponent('navCal');
              else
                this.props.app.functions.setShownComponent(null);
            }}
          >
            <CalendarMonthIcon />
          </IconButton>

        </Toolbar>
      </AppBar>
    );
  }
}

export default withAllContexts(Header);
