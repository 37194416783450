import { withAppContext } from "./AppContext";
import { withTheme } from '@mui/styles';

const withAllContexts = (Component) => {
    return withAppContext(withTheme(Component));
};

export { withAllContexts };


