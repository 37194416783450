import React from 'react';
import { withAllContexts } from '../AllContextsWrapper';
import { Icon } from './Icons';
import { Box, Grid, Typography, Avatar, Drawer as MuiDrawer, List, Divider, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import {
  CalendarViewWeekOutlined as CalendarViewWeekOutlinedIcon,
  CalendarViewMonthOutlined as CalendarViewMonthOutlinedIcon,
  CalendarViewDayOutlined as CalendarViewDayOutlinedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  ViewWeekOutlined as ViewWeekOutlinedIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  RecentActorsOutlined as RecentActorsOutlinedIcon,
  CalculateOutlined as CalculateOutlinedIcon,
} from "@mui/icons-material";

class SideMenu extends React.Component {

  closeSideMenu = () => {
    this.props.app.functions.setShownComponent(null);
  }

  render() {
    const user = this.props.socketIO.user;
    let userImage = user?.image ? '/avatars/' + user.image : '/avatars/default.png';

    return (
      <MuiDrawer
        anchor="left"
        open={this.props.app.state.shownComponent === 'sideMenu'}
        onClose={() => this.closeSideMenu()}
      >
        <Box
          sx={{
            width: 350,
            maxWidth: '80vw',
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            p: 5,
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Avatar sx={{ width: 100, height: 100, backgroundColor: 'white' }}
                alt={user?.name}
                src={userImage}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>{user?.name}</Typography>
              <Typography variant="subtitle" sx={{ textAlign: 'center' }}>{user?.email}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            p: 2,
          }}
        >
          <List
            sx={{
              // selected and (selected + hover) states
              '&& .Mui-selected, && .Mui-selected:hover': {
                '&, & .MuiListItemIcon-root': {
                  color: 'secondary.main',
                },
              },
              // hover states
              '& .MuiListItemButton-root:hover': {
                '&, & .MuiListItemIcon-root': {
                  color: 'secondary.main',
                },
              },
            }}>
            <ListItemButton onClick={() => {
              this.props.app.functions.setViewType('month');
              localStorage.setItem('view', 'month');
            }}
              selected={this.props.app.state.view === 'month'}
            >
              <ListItemIcon>
                <CalendarMonthOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Týždeň" />
            </ListItemButton>
            <ListItemButton onClick={() => {
              this.props.app.functions.setViewType('2weeks');
              localStorage.setItem('view', '2weeks');
            }}
              selected={this.props.app.state.view === '2weeks'}
            >
              <ListItemIcon>
                <CalendarViewWeekOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Dva týždne" />
            </ListItemButton>
            <ListItemButton onClick={() => {
              this.props.app.functions.setViewType('week');
              localStorage.setItem('view', 'week');
            }}
              selected={this.props.app.state.view === 'week'}
            >
              <ListItemIcon>
                <CalendarViewDayOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Týždeň" />
            </ListItemButton>
            <ListItemButton onClick={() => {
              this.props.app.functions.setViewType('3days');
              localStorage.setItem('view', '3days');
            }}
              selected={this.props.app.state.view === '3days'}
            >
              <ListItemIcon>
                <ViewWeekOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Tri dni" />
            </ListItemButton>
            <ListItemButton onClick={() => {
              this.props.app.functions.setViewType('1day');
              localStorage.setItem('view', '1day');
            }}
              selected={this.props.app.state.view === '1day'}
            >
              <ListItemIcon>
                <CalendarViewMonthOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Deň" />
            </ListItemButton>
          </List>
          <Divider />
          <List
            sx={{
              '&& .Mui-selected, && .Mui-selected:hover': {
                '&, & .MuiListItemIcon-root': {
                  color: 'secondary.main',
                },
              },
              '& .MuiListItemButton-root:hover': {
                '&, & .MuiListItemIcon-root': {
                  color: 'secondary.main',
                },
              },
            }}>
            <ListItemButton onClick={() => this.props.app.functions.toggleAddNewReservation()}>
              <ListItemIcon>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Nová rezervácia" />
            </ListItemButton>
            <ListItemButton onClick={() => this.props.app.functions.setShownComponent('clients', true)}>
              <ListItemIcon>
                <RecentActorsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Klienti" />
            </ListItemButton>
            <ListItemButton onClick={() => this.props.app.functions.setShownComponent('finances', true)}>
              <ListItemIcon>
                <CalculateOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Financie" />
            </ListItemButton>
          </List>
          <Divider />
          <List>
            <ListItemButton onClick={() => { window.location.href = '/api/logout' }}>
              <ListItemIcon>
                <Icon icon="sign-out-alt" className="edit-reservation-icon" />
              </ListItemIcon>
              <ListItemText primary="Odhlásiť sa" />
            </ListItemButton>
          </List>
        </Box>

      </MuiDrawer>
    );
  }
}

export default withAllContexts(SideMenu);
