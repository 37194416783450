import React from 'react';
import App from './App';
import Login from './Login';
import IsOffline from './IsOffline';
import { Offline, Online } from "react-detect-offline";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { withAllContexts } from './AllContextsWrapper';
import { Box, Backdrop, CircularProgress, Typography, Grid, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';


class IsLoggedInClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  render() {
    let socketIO = this.props.socketIO;

    if (!socketIO.isConnected || socketIO.isWaiting) {
      return (
        <Box className="app-loading_wrapper">

          <Backdrop
            sx={{ backgroundColor: 'rgba(0,0,0,0)' }}
            open={true}
            invisible={true}
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
              <Grid item>
                <Typography variant="h6">Pripájam sa...</Typography>
              </Grid>
            </Grid>
          </Backdrop>
        </Box >
      )
    } else if (!socketIO.isLoggedIn) {
      return (
        <Login />
      )
    } else {
      return (
        <App />
      )
    }
  }
};

const IsLoggedIn = withAllContexts(IsLoggedInClass);

class AppWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newVersionAvailable: false,
      waitingWorker: {},
      token: null
    };
  }

  componentDidMount() {
    const { newVersionAvailable } = this.state;
    serviceWorkerRegistration.register({ onUpdate: this.onServiceWorkerUpdate });

    if (newVersionAvailable) { //show snackbar with refresh button
      enqueueSnackbar("Novšia verzia je k dispozícií", {
        persist: true,
        variant: "info",
        action: this.refreshAction(),
      });
    }
  };

  refreshAction = (key) => { //render the snackbar button
    return (
      <React.Fragment>
        <Button
          className="snackbar-button"
          size="small"
          onClick={this.updateServiceWorker}
        >
          {"Aktualizovať"}
        </Button>
      </React.Fragment>
    );
  };

  onServiceWorkerUpdate = registration => {
    this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true
    })
  }
  updateServiceWorker = () => {
    const { waitingWorker } = this.state
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    this.setState({ newVersionAvailable: false })
    window.location.reload()
  }

  render() {
    const { newVersionAvailable } = this.state;
    if (newVersionAvailable) { //show snackbar with refresh button
      enqueueSnackbar("Novšia verzia je k dispozícií", {
        persist: true,
        variant: "info",
        action: this.refreshAction(),
      });
    }


    return (
      <>
        <Online polling={{ url: window.location.origin }}>
          <IsLoggedIn />
        </Online>
        <Offline polling={{ url: window.location.origin }}>
          <IsOffline />
        </Offline>
      </>
    )
  }
}

export default withAllContexts(AppWrapper);
