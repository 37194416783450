import React from 'react'
import { List, ListItem, ListItemText, Paper, Divider, Grid, Button, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { withAllContexts } from '../../../AllContextsWrapper'
import { enqueueSnackbar } from 'notistack';

class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new_message: '',
            sendButtonDisabled: false,
            sendRecapitulationButtonDisabled: false
        }
    }

    handleSendMessage = () => {
        if (this.state.new_message === '') {
            enqueueSnackbar("Správa nemôže byť prázdna", {
                variant: "error",
            });
            return;
        }

        this.setState({ sendButtonDisabled: true });

        let data = {
            to: this.props.reservation.client.phone,
            text: this.state.new_message,
            reservationId: this.props.reservation.id
        }

        this.props.socketIO.socket.emit('message:send', data, (response) => {
            console.log('message:send', response);
            if (response.status === 'success') {
                enqueueSnackbar("Správa úspešne odoslaná", {
                    variant: "success",
                });
                this.setState({ new_message: '', sendButtonDisabled: false });
            } else {
                enqueueSnackbar("Správu sa nepodarilo odoslať", {
                    variant: "error",
                });

                this.setState({ sendButtonDisabled: false });
            }

            this.handleReservationUpdate();
        });
    }

    handleReservationUpdate = () => {
        this.props.app.functions.updateMainCalendar();
        this.props.app.functions.updateCurrReservation(this.props.reservation.id);
    }

    handleMessageChange = (value) => {
        this.setState({ new_message: value });
    }

    handleSendRecapitulation = () => {
        this.setState({ sendRecapitulationButtonDisabled: true });

        this.props.socketIO.socket.emit('message:sendRecapitulation', this.props.reservation.id, (response) => {
            console.log('message:sendRecapitulation', response);
            if (response.status === 'success') {
                enqueueSnackbar("Rekapitulácia úspešne odoslaná", {
                    variant: "success",
                });
            } else {
                enqueueSnackbar("Rekapituláciu sa nepodarilo odoslať", {
                    variant: "error",
                });
            }

            this.setState({ sendRecapitulationButtonDisabled: false });
            this.handleReservationUpdate();
        });
    }

    formatDate = (date) => {
        let d = new Date(date);
        let year = d.getUTCFullYear();
        let month = d.getUTCMonth() + 1; // Months are zero based
        let day = d.getUTCDate();
        let hours = d.getUTCHours();
        let minutes = d.getUTCMinutes();
        let seconds = d.getUTCSeconds();

        // Pad single digit numbers with leading zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return `${day}. ${month}. ${year} ${hours}:${minutes}:${seconds}`;
    }




    render() {
        const { reservation } = this.props;
        const sortedMessages = reservation.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        return (
            <>
                <Grid container>
                    <Grid item xs={12} sx={{ marginBottom: 2 }}>
                        <Paper>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {sortedMessages.map((message) => (
                                    <>
                                        <ListItem key={message.id} Component={Paper}>
                                            <ListItemText primary={message.message} secondary={message.deliveryDateTime ? "Doručená: " + this.formatDate(message.deliveryDateTime) : "Nedoručená"} />
                                        </ListItem>
                                        {sortedMessages.indexOf(message) !== sortedMessages.length - 1 ?
                                            <Divider component="li" /> : null}
                                    </>
                                ))}
                            </List>

                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Správa"
                            type="text"
                            multiline
                            rows={4}
                            fullWidth
                            name="new_message"
                            value={this.state.new_message}
                            onChange={(e) => this.handleMessageChange(e.target.value)}
                            sx={{ width: '100%' }}
                        />


                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleSendMessage()} sx={{ width: '100%' }}
                            loading={this.state.sendButtonDisabled}
                            loadingIndicator="Odosielam správu..."
                        >
                            <span>Poslať správu</span>
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
                        <LoadingButton variant="outlined"
                            color="primary"
                            onClick={() => this.handleSendRecapitulation()} sx={{ width: '100%' }}
                            loading={this.state.sendRecapitulationButtonDisabled}
                            loadingIndicator="Odosielam rekapituláciu..."
                        >
                            <span>Poslať rekapituláciu objednávky</span>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withAllContexts(Messages);
