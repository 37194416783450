import React from 'react';
import './Assets/styles/main.scss';
import Header from './Components/Header';
import SideMenu from './Components/SideMenu';
import NavCalendar from './Components/NavCalendar';
import Calendar from './Components/Calendar';
import Modals from './Components/Modals';
import QRscannerModal from './Components/Modals/QRscanner';
import ReservationForm from './Components/Modals/Reservation/Form';
import { withAllContexts } from './AllContextsWrapper';
import { Box } from "@mui/material";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Box className="App">
        <Header
          date={this.props.app.state.date}
          view={this.props.app.state.view}
          today={this.props.app.state.today}
        />
        <SideMenu
          view={this.props.app.state.view}
        />
        <div className="content-wrapper">
          <div className={this.props.app.state.shownComponent === 'navCal' ? "side-wrapper shown" : "side-wrapper"}>
            <NavCalendar
              date={this.props.app.state.navDate}
              view={this.props.app.state.view}
              today={this.props.app.state.today}
            />
          </div>
          <div className="main-wrapper">
            <Calendar
              date={this.props.app.state.date}
              view={this.props.app.state.view}
              today={this.props.app.state.today}
              updateCalendar={this.props.app.state.updateCalendar}
            />
          </div>
        </div>

        <Modals
          addReservation={this.props.app.state.addReservation}
          addReservationDate={this.props.app.state.addReservationDate}
        />
        <ReservationForm
          isOpen={(this.props.app.state.addReservation || this.props.app.state.isCurrentlyEditing) ? true : false}
          initDate={this.props.app.state.addReservationDate}
          currentReservation={this.props.app.state.reservation}
        />
        <QRscannerModal />
      </Box>
    );
  }
}

export default withAllContexts(App);
