import React from "react";
import { withAllContexts } from "../../AllContextsWrapper";
import { QrReader } from 'react-qr-reader';
import beepSuccess from "../../Assets/sounds/beep_success.mp3";
import Drawer from "../Drawer";
import { enqueueSnackbar } from 'notistack';
//import beepError from "../../Assets/sounds/beep_error.mp3";


const ViewFinder = () => (
    <>
        <svg
            width="50px"
            viewBox="0 0 100 100"
            style={{
                top: 0,
                left: 0,
                zIndex: 1,
                boxSizing: 'border-box',
                border: '50px solid rgba(0, 0, 0, 0.3)',
                position: 'absolute',
                width: '100%',
                height: '100%',
            }}
        >
            <path
                fill="none"
                d="M13,0 L0,0 L0,13"
                stroke="rgba(255, 0, 0, 0.5)"
                strokeWidth="5"
            />
            <path
                fill="none"
                d="M0,87 L0,100 L13,100"
                stroke="rgba(255, 0, 0, 0.5)"
                strokeWidth="5"
            />
            <path
                fill="none"
                d="M87,100 L100,100 L100,87"
                stroke="rgba(255, 0, 0, 0.5)"
                strokeWidth="5"
            />
            <path
                fill="none"
                d="M100,13 L100,0 87,0"
                stroke="rgba(255, 0, 0, 0.5)"
                strokeWidth="5"
            />
        </svg>
    </>
);


class QRscannerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delay: 100,
            result: "No result",
        };
        this.handleScan = this.handleScan.bind(this);
    }

    playSound = (sound) => {
        let audio = new Audio(sound);
        audio.play();
    };

    handleScan(result, error) {
        if (!!result) {
            enqueueSnackbar("QR kód načítaný", {
                variant: "success",
            });
            this.playSound(beepSuccess);

            this.props.qrscanner.setResult(result.text);
        }

        if (!!error) {
            //console.info(error);
            //enqueueSnackbar("Nepodarilo sa načítať QR kód", {
            //    variant: "error",
            //});
        }
    }
    render() {
        return (
            <Drawer
                isOpen={this.props.qrscanner.state.active}
                onClose={() => this.props.qrscanner.cancel()}
                snapPosition={60}
                snapPositions={[60]}
            >
                <QrReader
                    scanDelay={this.state.delay}
                    onResult={this.handleScan}
                    style={{ width: "100%" }}
                    ViewFinder={ViewFinder}
                    constraints={{
                        facingMode: "environment",
                        aspectRatio: 1,
                    }}
                />
            </Drawer>
        );
    }
}

export default withAllContexts(QRscannerModal);
